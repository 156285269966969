import { Col, Container, Row, Visible } from "react-grid-system";

import { ReactComponent as FbIcon } from "../../assets/icons/icon_Facebook.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/icons/icon_linkedin.svg";
import { ReactComponent as MediumIcon } from "../../assets/icons/icon_Medium_logo.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icons/icon_Twitter.svg";
import logo from "../../assets/icons/logo.svg";
import { openCalendly } from "../../utils";
import styles from "./Footer.module.scss";
import { useHistory } from "react-router-dom";

type FooterLinks = Array<{
  title: string;
  links: Array<{
    title: string;
    action?: () => any;
    href?: string;
    Icon?: React.FunctionComponent<any>;
  }>;
}>;

const Footer = () => {
  const history = useHistory();

  const footerLinks: FooterLinks = [
    {
      title: "Information",
      links: [
        // { title: 'Blockchain',},
        //{ title: 'Product Demo', action: openCalendly },
        // { title: 'Pricing' }, { title: 'About us'}, { title: 'Features' }, { title: "FAQ's" }
      ],
    },
    // {
    //   title: 'Compliance',
    //   links: [{ title: 'WPA',}, { title: 'CCPA' }, { title: 'CRPA' }],
    // },
    {
      title: "Connect",
      links: [
        // { title: 'Blog' }, { title: 'Whitepaper' },
        { title: "Facebook", href: "https://www.facebook.com/pentaprivacy", Icon: FbIcon },
        { title: "Twitter", href: "https://twitter.com/Penta_GF", Icon: TwitterIcon },
        {
          title: "Linkedin",
          href: "https://www.linkedin.com/company/penta-network/",
          Icon: LinkedInIcon,
        },
        { title: "Medium", href: "https://medium.com/@penta2018", Icon: MediumIcon },
        // { title: 'Google My Business' }
      ],
    },
    {
      title: "Legal",
      links: [
        { title: "Privacy Policy", action: () => history.push("/privacy-policy") },
        // { title: 'Terms Of Use' }
      ],
    },
  ];

  return (
    <footer className={styles.footer}>
      <Container className="u-no-padding color-white">
        <Row nogutter>
          <Col md={4} sm={12} className={styles.brandDesc}>
            <div className={`u-flex u-margin-bottom_2 ${styles.logoDesc}`}>
              <div className="u-flex">
                <div className={styles.logoContainer}>
                  <img src={logo} alt="logo" className={styles.logo} />
                  <span className={styles.shadow} />
                </div>
                <span className={`u-margin-left_1 u-text-left ${styles.desc}`}>
                  <h4>Penta Privacy Lock &#8482;</h4>
                  <p>
                    Copyright 2021 <span className="u-space-nowrap">Penta Privacy Lock </span>
                    <br /> All rights reserved.
                  </p>
                </span>
              </div>
            </div>
            <p>
              Data Privacy Compliance Made Easy with{" "}
              <span className="u-space-nowrap">Penta Privacy Lock </span>
            </p>
          </Col>
          <Col md={8} xs={12}>
            <div className={styles.linksContainer}>
              <Row>
                {footerLinks
                  .filter(({ title }) => title !== "Connect")
                  .map((footerLink, i) => (
                    <Col key={footerLink.title} md={3}>
                      <h4>{footerLink.title}</h4>
                      {footerLink.links.map((link, idx) => (
                        <a
                          href={link.href}
                          onClick={(e) => {
                            if (link.action) {
                              e.preventDefault();
                              link.action();
                            }
                          }}
                          target="_blank"
                          rel="noreferrer"
                          key={link.title}
                        >
                          {link.title}
                        </a>
                      ))}
                    </Col>
                  ))}
              </Row>
            </div>
          </Col>
          <Visible sm xs>
            <div className={styles.socialMediaIcons}>
              {footerLinks[1].links.map((link, i) => (
                <a
                  href={link.href}
                  onClick={(e) => {
                    if (link.action) {
                      e.preventDefault();
                      link.action();
                    }
                  }}
                  target="_blank"
                  rel="noreferrer"
                  key={link.title}
                >
                  {link.Icon && <link.Icon />}
                </a>
              ))}
            </div>
          </Visible>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
