import { ReactNode, ReactNodeArray, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import styles from "./styles.module.scss";
// @ts-ignore
import PinchZoomPan from "react-responsive-pinch-zoom-pan";


function LightboxImage(props: { children: ReactNode | ReactNodeArray}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleLightBoxOpen = () => {
    setIsOpen(true);
  }

  return (
    <>
    <div className={styles.wrapper} role="button" onClick={handleLightBoxOpen}>
      {props.children}
    </div>
    <Dialog fullScreen onClose={() => setIsOpen(false)} open={isOpen} classes={{ paper: styles.scrollPaper }}>
    <span role="button" title="Close" onClick={() => setIsOpen(false)} className={styles.closeButton}>x</span>
    <div style={{ width: 'calc(100vw - 4em)', height: 'calc(100vh - 8em)', margin: '4em auto' }}>
      <PinchZoomPan maxScale={4} position="center" initialScale={1} top>
        {props.children}
      </PinchZoomPan>
      </div>
    </Dialog>
    </>
  )
}

export default LightboxImage

