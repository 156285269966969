import { GAEventCategory, trackEvent } from "./utils/analytics";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { lazy, useEffect, useState } from "react";

import Aboutus from "./pages/Aboutus";
import Acknowledge from "./pages/Acknowledge";
import CCPA from "./pages/Compliance/ccpa";
import Features from "./pages/Features";
import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import Resources from "./pages/Resources";
import UseCases from "./pages/Usecases";
//import { isCalendlyEvent } from "./utils";
import { logToGoogleAnalytics } from "./utils/analytics";

// Less frequently used
const Faq = lazy(() => import("./pages/FAQ"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));

const Routes = () => {
  const location = useLocation();
  const history = useHistory();
  const [scheduled, setScheduled] = useState(false);
  // @ts-ignore
  const background = location.state && location.state.background;

  useEffect(() => {
    history.replace({ state: { background: undefined } });
    /*  window.addEventListener("message", (e) => {
      if (!scheduled && isCalendlyEvent(e) && e.data.event === "calendly.event_scheduled") {
        setScheduled(true);
        history.push({ pathname: "/acknowledge", state: { background: location } });
        trackEvent(GAEventCategory.SCHEDULE_DEMO, "click", e.data.event);
        //  Calendly throws the event thrice on schedule. Used to debounce function call.
        setTimeout(() => {
          setScheduled(false);
        }, 5000);
      }
    }); */
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    logToGoogleAnalytics(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <Switch location={background || location}>
        <Route exact path="/" component={Home} />
        <Route exact path="/contact-us" component={Aboutus} />
        <Route exact path="/features" component={Features} />
        <Route exact path="/compliance" component={CCPA} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/pricing" component={Pricing} />
        <Route exact path="/usecases" component={UseCases} />
        <Route exact path="/resources" component={Resources} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route component={Home} />
      </Switch>
      {background && <Route path="/acknowledge" children={<Acknowledge />} />}
    </>
  );
};

export default Routes;
