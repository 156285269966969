const features = {
  basic: ["Tracks up to 50,000 accounts", "Standard Dashboard", "Data Inventory Management", "Consumer Request Tracking", "Data Mapping", "Automated Report Generation"],
  premium: ["Tracks up to 500,000 accounts", "Customized Branded Dashboard", "Data Inventory Management", "Consumer Request Tracking", "Data Mapping", "Automated Report Generation", "Notification Center"],
  enterprise: ["Unlimited Account Tracking", "Customized Branded Dashboard", "Data Inventory Management", "Consumer Requests Tracking", "Data Mapping", "Customized Report Generation", "Notification Center", "On-Premise/Cloud Deployments", "Integration with CRM, ERP, other data systems"]
};

const pricing = [
  {
    license: "Basic",
    price: 50,
    features: features.basic,
    heading: 'SMALL BUSINESS',
  },
  {
    license: "Premium",
    price: 250,
    features: features.premium,
    heading: "MOST POPULAR",
  },
  {
    license: "Enterprise",
    features: features.enterprise,
    heading: "PREMIUM",
  }
];

export default pricing;
