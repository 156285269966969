import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useEffect, useState } from "react";

import { API_BASE } from "../../utils/api";
import { Col } from "react-grid-system";
import firebase from "../../utils/firebase";
import iconDelete from "../../assets/icons/icon_trash.svg";
import iconNewspaper from "../../assets/icons/newspaper.svg";
import styles from "./styles.module.scss";

export type NewsLink = {
  link: string;
  title?: string;
  description?: string;
  metadata?: any;
};

type NewsLinks = Array<NewsLink>;

type Props = {
  showMenu?: boolean;
};

const generateUID = (): string => {
  // I generate the UID from two parts here
  // to ensure the random number provide enough bits.
  let firstPart: any = (Math.random() * 46656) | 0;
  let secondPart: any = (Math.random() * 46656) | 0;
  firstPart = ("000" + firstPart.toString(36)).slice(-3);
  secondPart = ("000" + secondPart.toString(36)).slice(-3);
  return firstPart + secondPart;
};
const buildImageUrl = (link: string, imagePath: string) => {
  if (!imagePath) {
    return iconNewspaper;
  }
  if (imagePath.startsWith("http")) {
    return imagePath;
  }
  // Image path can be absolute as well as relative
  const url = new URL(link);
  const origin = url.origin.endsWith("/") ? url.origin.slice(0, -1) : url.origin;
  return origin + imagePath;
};

const NewsCards = (props: Props) => {
  const [articleMeta, setArticleMeta] = useState<NewsLinks>([]);

  const fetchMetadata = async (links: string[], existingNews: NewsLinks) => {
    try {
      const res = await fetch(`${API_BASE}/metadata`, {
        method: "post",
        body: JSON.stringify({ links }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      if (res.status === 200) {
        const ogData = data.data;
        const metadata = existingNews.reduce((mergedArticles, news) => {
          if (!news.metadata) {
            const fetchedMeta = ogData.find((og: any) => og.requestUrl === news.link);
            if (fetchedMeta) {
              return mergedArticles.concat({ ...news, metadata: fetchedMeta });
            }
            return mergedArticles.concat(news);
          }
          return mergedArticles.concat(news);
        }, [] as NewsLinks);
        setArticleMeta(metadata);
        firebase.database().ref("news").set(metadata);
      }
    } catch (error) {
      console.error(error);
    }
  };
  let nf = 0;
  const fetchNewsArticle = (value: firebase.database.DataSnapshot) => {
    const newsLinks = value.toJSON() || {};
    const links = Object.values(newsLinks);
    //sxm to get latest articles on top, since we do not have any dates to sort by, simply reverse
    if (nf === 0) {
      links.reverse();
      nf++;
    }
    setArticleMeta(links);
    const linksWithoutMeta = links.reduce((withoutMeta, newsLink) => {
      return !newsLink.metadata ? withoutMeta.concat(newsLink.link) : withoutMeta;
    }, []);
    if (linksWithoutMeta.length) {
      fetchMetadata(linksWithoutMeta, links);
    }
  };

  useEffect(() => {
    firebase.database().ref("/news").on("value", fetchNewsArticle);
    return () => {
      firebase.database().ref("/news").off("value", fetchNewsArticle);
    };
  }, []);

  const removeLink = (article: NewsLink) => {
    const updatedLinks = articleMeta.filter((news) => news.link !== article.link);
    firebase.database().ref("/news").set(updatedLinks);
  };

  const renderCard = (article: any): React.ReactNode => {
    const title = article.title || article.metadata.ogTitle;
    const subtitle = article.title ? article.metadata.ogTitle : "";
    const description = article.description || article.metadata.ogDescription;
    const image = article.metadata.ogImage
      ? buildImageUrl(article.metadata.ogUrl, article.metadata.ogImage?.url)
      : undefined;
    return (
      /*       <div key={generateUID()}>
       */ <>
        <a className={styles.anchor} href={article.link} target="_blank" rel="noreferrer">
          <div className={styles.flexContainer}>
            <div className={`${styles.flexItem} ${styles.imageWrapper}`}>
              {image ? (
                <img src={image} alt="Author" className={styles.newsImage} />
              ) : (
                <div className={styles.imageFallback} />
              )}
            </div>
            <div className={`${styles.flexItem}`}>
              <h4>{title}</h4>
              <h5 className="u-no-margin">{subtitle}</h5>
            </div>
          </div>
          <p>{description}</p>
        </a>
        {props.showMenu && (
          <div
            className={styles.optionButton}
            role="button"
            onClick={(e) => {
              // @ts-ignore
              removeLink(article as NewsLink);
            }}
          >
            <img src={iconDelete} alt="..." />
          </div>
        )}
      </>
    );
  };

  const articles = articleMeta.filter((article) => article.metadata);
  const columnsCountBreakPoints = { 400: 1, 800: 2, 1100: 3 };
  if (articles.length == 0) return null;
  return (
    <div className={`${styles.masonryWrapper}`}>
      <ResponsiveMasonry columnsCountBreakPoints={columnsCountBreakPoints} gutter="10px">
        <Masonry>
          {articles.map((article, i) => (
            <div key={i} className={`${styles.newscard}`}>
              {renderCard(article)}
            </div>
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
};

export default NewsCards;
