import { Col, Container, Row } from "react-grid-system";
import Banner from "../../components/Banner";
import pricingData from "./pricingData";
import styles from "./styles.module.scss";
import iconTick from "../../assets/icons/icon_tick.svg";
import { ReactComponent as IconRightArrow } from "../../assets/icons/icon_arrow_right.svg";
import Button from "../../components/Button";
import { openCalendly } from "../../utils";

const renderPricingCard = (
  licenseType: string,
  features: Array<string>,
  heading: string,
  price?: number,
) => (
  <Col>
    <div
      className={`${styles.wrapper} ${styles.withHeading}`}
    >
      <div className={`${styles.heading} ${licenseType === "Premium" ? styles.popularHeading : ''}`}>{heading}</div>
      <div
      className={styles.cardContent}
    >
      <div className={styles.header}>
        <span>
          <h5 className={styles.license}>LICENSE</h5>
          <h2>{licenseType}</h2>
        </span>
        {price && (
          <span className={styles.priceWrap}>
            <span className={styles.symbol}>$</span>
            <h1>{price}</h1>
          </span>
        )}
        </div>
      <ul className={styles.content}>
        {features.map((feat) => (
          <li key={feat} className={styles.featRow}>
            <img src={iconTick} alt="*" />
            {feat}
          </li>
        ))}
      </ul>
      {price ? (
        <button onClick={openCalendly} className={`textual-button ${styles.purchaseButton}`}>
          Purchase Now
          <IconRightArrow className={styles.arrow} />
        </button>
      ) : (
        <Button onClick={openCalendly} variant="fancy" className="u-button-rounded color-text u-margin-auto">
          Get a Quote
        </Button>
      )}
    </div>
    </div>
  </Col>
);

const Pricing = () => {
  return (
    <>
      <Banner
        title="Data Privacy Compliance Made Easy"
        desc="Automate and simplify CCPA compliance today."
        imgUrl="/assets/images/ipad_cropped.png"
      />
      <div className="bg-white">
        <Container className="padding-vertical_lg">
          <Row>
            {pricingData.map((data, i) =>
              renderPricingCard(data.license, data.features, data.heading, data.price)
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Pricing;
