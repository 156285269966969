import { ReactNode } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogTitle } from '@material-ui/core';

interface Props extends DialogProps {
  children: ReactNode;
  onClose: () => any;
  open: boolean;
  title?: string;
}

export default function TransitionsModal({ children, onClose, open, title, ...props }: Props) {
  return (
    <div>
       <Dialog
        open={open}
        onClose={onClose}
        {...props}
      >
        {title && <DialogTitle id="form-dialog-title">{title}</DialogTitle>}
        <DialogContent>
          {children}
        </DialogContent>
      </Dialog>
    </div>
  );
}
