import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MUISelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import styles from "./styles.module.scss";

type Props = {
  label: string;
  value: string | number;
  onChange: (value: string) => void;
  options: Array<{ label: string, value: string | number }>
};

const Select = (props: Props) => {
  return (
    <FormControl variant="filled" className={styles.formControl}>
      <InputLabel className={styles.label} htmlFor={props.label}>{props.label}</InputLabel>
      <MUISelect
        className={styles.selectComponent}
        value={props.value}
        // @ts-ignore
        onChange={e => props.onChange(e.target.value)}
      >
        {props.options.map(({ label, value }) => (
          <MenuItem key={value} value={value}>{label}</MenuItem>
        ))}
      </MUISelect>
    </FormControl>
  )
}

export default Select;
