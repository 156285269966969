import TextField from "@material-ui/core/TextField";
import styles from "./styles.module.scss";

type Props = {
  label: string;
  value: string;
  onChange: (value: string) => void;
  type?: string;
  className?: string;
  autoComplete?: string;
  required?: boolean;
  error?: boolean;
};

const Input = (props: Props) => {
  return (
    <div className={`${styles.formItem} ${props.className || ""}`}>
      <TextField
        className={`${styles.input} ${props.className || ""}`}
        label={props.label}
        variant="outlined"
        value={props.value}
        fullWidth={true}
        onChange={(e) => props.onChange(e.target.value)}
        type={props.type}
        autoComplete={props.autoComplete}
        required={props.required}
        error={props.error}
      />
    </div>
  );
};

export default Input;
