
// NOTE: Use types to keep the actual categories/labels/actions being sent to GA in check.
type GAEventAction = 'click';
export enum GAEventCategory {
  SCHEDULE_DEMO = 'SCHEDULE_DEMO',
  CALCULATE_FINE = 'CALCULATE_FINE',
};
type GAEventLabel = string; // Just make sure it's useful and consistent.

/**
 * Sends an event to Google Analytics
 * @param {GAEventCategory} category The category of the event
 * @param {GAEventAction} action The action being tracked
 * @param {GAEventLabel} label The label of the event
 * @param {number} value An optional value for the event.
 * @returns {void}
 */
 export function trackEvent(
  category: GAEventCategory,
  action: GAEventAction,
  label: GAEventLabel,
  value?: number | string,
) {
  if (window.ga && process.env.NODE_ENV === "production") {
    window.ga('send', 'event', category, action, label, value);
  }
}

/**
 * Logs a page view to Google Analytics
 * @param {string} match The matched router path
 * @returns {void}
 */
export function logToGoogleAnalytics(match: string) {
  if (typeof window.ga === 'function' && process.env.NODE_ENV === "production") {
    window.ga('set', 'page', match);
    window.ga('send', 'pageview');
  }
}
