import { useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import Snackbar from '@material-ui/core/Snackbar';
// @ts-ignore
import Shake from "react-reveal/Shake";
import firebase from "../../utils/firebase";
import Input from "../Input";
import Button from "../Button";
import styles from "./WhitePaper.module.scss";
import constant from "../../utils/constants";
import { getSendEmailConfig } from "../../utils/apiConfig";
import { fetchData } from "../../utils/api";

const { emailRegex, blacklistedEmailRegex, nameRegex } = constant.regex;

const initialState = {
  name: "", email: ""
}

const WhitePaper = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [contactInfo, setContactInfo] = useState(initialState);
  const [notification, setNotification] = useState('');
  const [errors, setErrors] = useState(initialState);

  const isFormValid = () => {
    const { email, name } = contactInfo;
    const _errors: typeof contactInfo = { ...initialState};
    if (!emailRegex.test(email) || blacklistedEmailRegex.test(email)) {
      _errors.email = 'Please enter your email.'
    }
    if (!name.trim().length || !nameRegex.test(contactInfo.name)) {
      _errors.name = 'Please enter your name.'
    }
    setErrors(_errors);
    if (!errors.name && !errors.email) {
      return true;
    }
    return false;
  }

  const getWhitePaper = () => {
    if (isFormValid()) {
      setErrors(initialState);
      setIsLoading(true);
      firebase
      .database()
      .ref("/whitepaper")
      .push(contactInfo)
      .then(() => {
        setIsLoading(false);
        fetchData(getSendEmailConfig({ email: contactInfo.email, contentKey: 'whitepaper-download', name: contactInfo.name }));
        setNotification(`You'll be recieving our whitepaper at ${contactInfo.email} shortly!`);
        setContactInfo(initialState);
      });
    }
  };

  return (
    <section className={styles.whitepaper}>
      <Container>
        <Row>
          <Col md={6}>
            <h2 className="u-line-height-std">
            Download our free white paper and get up to speed on CCPA compliance for your business.
            </h2>
            <Input
              label="Full Name"
              className={styles.contactInput}
              value={contactInfo.name}
              autoComplete="name"
              onChange={(val) => setContactInfo({ ...contactInfo, name: val })}
            />
            <Shake spy={errors.name}>
              <h6 className="u-no-margin input-error">{errors.name}</h6>
            </Shake>
            <Input
              label="Email"
              type="email"
              autoComplete="email"
              className={styles.contactInput}
              value={contactInfo.email}
              onChange={(val) => setContactInfo({ ...contactInfo, email: val })}
            />
            <Shake spy={errors.email}>
              <h6 className="u-no-margin input-error">{errors.email}</h6>
            </Shake>
            <Button
              className="u-full-width"
              variant="fancy"
              onClick={getWhitePaper}
              isLoading={isLoading}
            >
              Get White Paper
            </Button>
          </Col>
          <Col md={6}>
            <div className={styles.whitepaperContainer} />
          </Col>
        </Row>
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
        open={!!notification}
        onClose={() => setNotification('')}
        message={notification}
        autoHideDuration={6000}
        classes={{ root: 'toaster' }}
      />
    </section>
  )
}

export default WhitePaper;
