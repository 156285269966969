import { useState } from "react";
import { Container, Row, Col, Hidden, useScreenClass } from "react-grid-system";
// @ts-ignore
import Slide from "react-reveal/Slide";
import { Carousel } from "react-responsive-carousel";
import DialogActions from "@material-ui/core/DialogActions";

import Banner from "../../../components/Banner";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import styles from "./styles.module.scss";
import icon1 from "../../../assets/icons/icon_compliance_1.svg";
import icon2 from "../../../assets/icons/icon_compliance_2.svg";
import icon3 from "../../../assets/icons/icon_compliance_3.svg";
import icon4 from "../../../assets/icons/icon_compliance_4.svg";
import icon5 from "../../../assets/icons/icon_compliance_5.svg";
import icon6 from "../../../assets/icons/icon_compliance_6.svg";
import iconDataManagement from "../../../assets/icons/icon_data_inventory_management.svg";
import iconConsumerTracking from "../../../assets/icons/icon_consumer_request_tracking.svg";
import iconAuditing from "../../../assets/icons/icon_auditing_and_reporting.svg";
import iconDataMapping from "../../../assets/icons/icon_data_mapping.svg";
import iconNotification from "../../../assets/icons/icon_notification_center.svg";
import iconNotepad from "../../../assets/icons/icon_notepad.svg";
import iconGrid from "../../../assets/icons/feature_banner_bg.png";
import iconConsentManagement from "../../../assets/icons/icon_consent_management.png";
import iconVendorManagement from "../../../assets/icons/icon_vendor_management.png";

import { ReactComponent as Icon } from "../../../assets/icons/icon_tick.svg";
import { ReactComponent as IconRight } from "../../../assets/icons/icon_arrow_right.svg";
import { ReactComponent as IconLeft } from "../../../assets/icons/icon_arrow_left.svg";
import { openCalendly } from "../../../utils";
import WhitePaper from "../../../components/Whitepaper";
import RequirementForm from "../../../components/RequirementForm";

const complianceChecklist = [
  {
    icon: icon1,
    desc: "Find out if CCPA impacts your company",
  },
  {
    icon: icon2,
    desc: "Update privacy disclosures",
  },
  {
    icon: icon3,
    desc: "Make opting-out simple",
  },
  {
    icon: icon4,
    desc: "Manage data inventory for compliance",
  },
  {
    icon: icon5,
    desc: "Implement consumer request processes and tracking",
  },
  {
    icon: icon6,
    desc: "Operationalize compliance reporting",
  },
];

const features = [
  {
    icon: iconDataManagement,
    label: "Data Inventory Management",
  },
  {
    icon: iconConsumerTracking,
    label: "Consumer Request Tracking",
  },
  {
    icon: iconAuditing,
    label: "Auditing & Reporting",
  },
  {
    icon: iconDataMapping,
    label: "Data Mapping",
  },
  {
    icon: iconConsentManagement,
    label: "Consent Management",
  },
  {
    icon: iconNotification,
    label: "Notifcation Center",
  },
  {
    icon: iconVendorManagement,
    label: "Vendor Management",
  },
];

const faqs = [
  {
    title: "Who needs to comply?",
    content: (
      <>
        <p>
        The CCPA applies to for-profit businesses that do business in California and meet any of the following criteria:
        </p>
        <ul>
          <li>Have a gross annual revenue of over $25 million;</li>
          <li>
          Buy, receive, or sell the personal information of 50,000 or more California residents, households, or devices; or
          </li>
          <li>
          Derive 50% or more of their annual revenue from selling California residents’ personal information.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: "Personal Information",
    content: (
      <>
        <h4>
          Personal information is information that identifies, relates to, or could reasonably be linked with you or your household. It can include the following:
        </h4>
        <div className="u-flex">
          <ul>
            <li>Your name</li>
            <li>Social security number</li>
            <li>Email address</li>
          </ul>
          <ul>
            <li>Records of products purchased</li>
            <li>Internet browsing history</li>
            <li>Geolocation data, fingerprints</li>
            <li>Inferences from personal information that could create a profile about your preferences and characteristics</li>
          </ul>
        </div>
      </>
    ),
  },
  {
    title: "Privacy Rights for Consumers",
    content: (
      <>
        <h4>The following are privacy rights granted to California consumers under the CCPA:</h4>
        <ul>
          <li>The right to know about the personal information a business collects about them and how it is used and shared;</li>
          <li>The right to delete personal information collected from them (with some exceptions);</li>
          <li>The right to opt-out of the sale of their personal information; </li>
          <li>The right to non-discrimination for exercising their CCPA rights</li>
        </ul>
      </>
    ),
  },
  {
    title: "Responding to Consumer Requests",
    content: (
        <h4>Businesses must respond to consumer requests within 45 calendar days. They can extend that deadline by another 45 days (90 days total) if they notify you.</h4>
    ),
  },
  {
    title: "What is covered by the Right to Delete in CCPA?",
    content: (
        <h4>Consumers may request that businesses delete personal information they collected and tell their service providers to do the same.</h4>
    ),
  },
  {
    title: "Fines for CCPA Violations",
    content: (
      <>
        <h4>The CCPA includes two kinds of penalties for compliance violations: enforcement actions by the state and a Private Right of Action for consumers.</h4>
        <h4 className="u-no-margin">State Enforcement</h4>
        <p className={styles.para}>Violations of the CCPA are subject to enforcement by the California attorney general’s office, which can seek civil penalties of $2,500 to $7,500 for each intentional violation after notice and a 30-day opportunity to cure.</p>
        <h4 className="u-no-margin">Consumer Private Right of Action</h4>
        <p className={styles.para}>CCPA also grants consumers the right to sue. Consumers can bring private actions against companies for actual damages or statutory damages. Statutory damages are calculated between $100 and $750, per consumer, per incident.</p>
      </>
    ),
  },
];

const CCPA = () => {
  const [selectedSlideIndex, setSelectedSlideIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formModalOpen, setFormModalOpen] = useState(false);
  const screenClass = useScreenClass();

  return (
    <>
      <Banner
        title="Let Penta Privacy Lock power your CCPA compliance."
        desc="Privacy Lock integrates seamlessly with your data systems to enable automation of CCPA consumer requests, data mapping, and reporting."
        imgUrl="/assets/images/aboutus_header.png"
      />
      <div className="bg-white">
      <header className={`u-text-center u-font-exo u-font-strong ${styles.header}`}>
        CCPA
        <h6 className="u-no-margin">California Consumer Privacy Act (CCPA)</h6>
      </header>
        <Container
          className="u-line-height-std padding-vertical_std u-text-left"
        >
          <p>
            CCPA is a sweeping data privacy law that strengthens privacy rights and consumer
            protection for residents of California. It applies to any business that provides goods
            or services to California consumers.
          </p>
          <p>
            Businesses face many challenges in bringing their data systems and business practices
            into compliance with CCPA. While the law went into effect on January 1, 2020, most
            businesses have yet to operationalize CCPA compliance requirements. For a list of CCPA
            compliance requirements <button onClick={() => setFormModalOpen(true)} className="textual-button link">click here.</button>
          </p>
          <p>
            Fortunately, Penta Privacy Lock is here to make CCPA compliance easy, so that you can
            protect your business and your customers’ private information.
          </p>
        </Container>
        <div className="bg-theme-hover">
          <Container style={{ padding: "1em" }}>
            <h2 className="u-text-center">Compliance Checklist</h2>
            <Row justify="around">
              {complianceChecklist.map((checklist, i) => (
                <Col className={styles.checklist} sm={4} xs={6} key={i}>
                  <div className="u-text-center">
                    <span className={styles.checklistNumber}>{i + 1}</span>
                  </div>
                  <img className={styles.complianceIcon} src={checklist.icon} alt={(i + 1).toString()} />
                  <p>{checklist.desc}</p>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
        <Container style={{ padding: "2em" }}>
          <Row>
            <Col sm={12} md={7} className="u-letter-spacing-std">
              <h2>About CCPA</h2>
              <p className="u-line-height-std">
                On January 1, 2020, CCPA compliance became mandatory for all “covered” companies
                doing business with California residents. Compliance violations can result in steep
                fines.
              </p>
              <p className="u-line-height-std">
                Penta Privacy Lock is the first CCPA compliance solution that leverages blockchain
                technology to bring trust and robust automation to data privacy compliance. Our data
                privacy experts have architected Penta Privacy Lock to ensure privacy compliance is
                easy to implement and affordable.
              </p>
              <h4 className="color-theme">Simplify your CCPA Compliance with Penta</h4>
              <Row align="center">
                {features.map((feature, i) => (
                  <Col
                    className={`color-theme ${styles.featureIcons}`}
                    md={3}
                    sm={4}
                    xs={6}
                    key={i}
                  >
                    <div className="u-flex-centered u-margin-bottom_1">
                      <img src={feature.icon} className={styles.featIcon} alt="" />
                      <span>{feature.label}</span>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Hidden xs sm>
              <Col md={5}>
                <div className={styles.imgContainer}>
                  <img
                    src="/assets/images/aboutus_1.svg"
                    alt="about us"
                    className={styles.tileImg}
                  />
                </div>
              </Col>
            </Hidden>
          </Row>
        </Container>
        <section className={styles.featureCarousalContainer}>
          <div className={`${styles.featureList} spaced-padding-left`}>
            <h2>CCPA Details</h2>
              {faqs.map((qa, i) => (
                <Slide left key={i}>
                  <div
                    className={`${styles.featureRow} ${
                      selectedSlideIndex === i ? styles.currentItem : ""
                    }`}
                    onClick={() => {
                      setSelectedSlideIndex(i);
                      if (screenClass === "xs") {
                        setIsModalOpen(true);
                      }
                    }}
                  >
                    <Icon />
                    <h4>{qa.title}</h4>
                  </div>
                </Slide>
              ))}
          </div>
          <div className={`${styles.featureCarousal} ${screenClass !== 'xs' ? 'spaced-padding-right' : ''}`}>
            <Carousel
              showArrows={false}
              selectedItem={selectedSlideIndex}
              showIndicators={false}
              showStatus={false}
              onChange={(index) => setSelectedSlideIndex(index)}
              showThumbs={false}
              dynamicHeight
            >
              {faqs.map((qa, i) => (
                <div key={i} className={`${styles.carousalItem} u-text-left`}>
                  <h2>{qa.title}</h2>
                  <div>{qa.content}</div>
                  {selectedSlideIndex === faqs.length - 1 ? (
                    <div
                      role="button"
                      onClick={() =>
                        setSelectedSlideIndex((currentIndex) => currentIndex - 1)
                      }
                      className={styles.carousalButton}
                    >
                      <span>
                        Read Previous <IconLeft className={styles.arrowLeft} />
                      </span>
                    </div>
                  ) : (
                    <div
                      role="button"
                      onClick={() =>
                        setSelectedSlideIndex((currentIndex) => currentIndex + 1)
                      }
                      className={styles.carousalButton}
                    >
                      <span>
                        Read Next <IconRight className={styles.arrowRight} />
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </Carousel>
          </div>
        </section>
        <div className={styles.disclaimer}>
          <h5 style={{ marginBottom: 8 }}>Legal Disclaimer</h5>
          <h5>
            Disclaimer. The information provided on this website does not, and is not intended to,
            constitute legal advice; instead, all information, content, and materials available on
            this site are for general informational purposes only.
          </h5>
        </div>
        <WhitePaper />
        <div className="padding-vertical_std">
          <Container>
            <Row align="center">
              <Col md={8} sm={12} lg={7} className="u-line-height-std u-letter-spacing-std">
                <p>
                  Time to operationalize CCPA compliance for your business! With Penta Privacy Lock
                  you can quickly implement a privacy solution that is effective and provides
                  protection for your business and your customers. Make compliance easy and
                  affordable with Penta Privacy Lock.
                </p>
              </Col>
              <Hidden xs sm md>
                <Col lg={1}></Col>
              </Hidden>
              <Col md={4} sm={12} style={{ justifyContent: "center", display: "flex" }}>
                <div className={styles.notepadContainer}>
                  <img src={iconGrid} className={styles.bg} alt="" />
                  <Hidden sm xs>
                    <img src={iconNotepad} className={styles.notepad} alt="notepad" />
                  </Hidden>
                  <Button
                    onClick={openCalendly}
                    className={styles.demoBtn}
                    variant="fancy"
                    size="small"
                  >
                    Demo Today
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby={faqs[selectedSlideIndex].title}
      >
        <h2>{faqs[selectedSlideIndex].title}</h2>
        {faqs[selectedSlideIndex].content}
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)}>Close</Button>
        </DialogActions>
      </Modal>
      <RequirementForm
        title="Checklist"
        type="checklist"
        onClose={() => setFormModalOpen(false)}
        open={formModalOpen}
      />
    </>
  );
};

export default CCPA;
