import { Col, Container, Row } from "react-grid-system";

import Banner from "../../components/Banner";
import Button from "../../components/Button";
import TextField from "@material-ui/core/TextField";
import constants from "../../utils/constants";
import firebase from "../../utils/firebase";
import styles from "./styles.module.scss";
import { useState } from "react";

// const team = [
//   {
//     image: "/assets/images/team/ceo.png",
//     designation: "Chief Executive",
//     name: "David Ritter",
//     description:
//       "David Ritter is the CEO of Penta. Receiving his degree in financial and sociopolitical history from USC, Mr. Ritter served as Project Director of DGI, managing projects with a portfolio value of more than USD$1 billion. David also has managed a Hollywood P&A Fund in partnership with major studios. As Penta CEO, in 2018 Mr. Ritter was invited by MIT as the blockchain expert for its Global Startup Workshop forum. David is also an active philanthropist and community advocate.",
//   },
//   {
//     image: "/assets/images/team/cto.jpg",
//     designation: "Chief Technology Officer",
//     name: "Steve Melnikoff",
//     description:
//       "Dr. Melnikoff is chief scientist, and with David Ritter co-founder of Penta. He has degrees in physics and mathematics from MIT, receiving his PhD in physics from UC Riverside. Starting at the Lawrence Livermore National Lab, Steve has continued a career in information physics with domain expertise in large-scale, web-based data systems. He has worked on advanced government related projects in both the public and private technology sectors, as well as in university research.",
//   },
//   {
//     image: "/assets/images/team/pel.png",
//     designation: "Products Engineering Lead",
//     name: "Dr. Ed Kazmierczak",
//     description:
//       "Dr. Ed Kazmierczak holds a PhD in computer science and has served both as researcher and professor at the University of Melbourne. His area is software engineering for large-scale distributed high integrity systems. Projects include partnerships with the Australian Defence Sciences and Technology Organisation (DSTO) and in-depth physiological modelling of the human kidney. Recently Ed has been involved in the design of in-ground sensor networks for crop monitoring and multispectral image data integration.",
//   },
//   {
//     image: "/assets/images/team/sid.png",
//     designation: "Social Impact Director",
//     name: "Lucia Gallardo",
//     description:
//       "Lucia Galladro is a Honduran entrepreneur and the Founder & CEO of Emerge. Focussing on technology for social impact, she is currently nominated for the 2018 Global SDG Awards and Newsweek’s 2019 Blockchain Impact Awards. Prior to Emerge Lucia co-founded Dona un Libro, Cambia una Mente, an education centered non-profit and 2016 recipient of President Obama’s Young Leaders of the Americas Fellowship. Among her many awards is the 2017 Venture for Canada Fellowship. She has experience in both public and private sectors: from immigration and trade relations on diplomatic missions, to mobile tech and early-stage investments.",
//   },
//   {
//     image: "/assets/images/team/gpc.jpg",
//     designation: "Government Projects Coordinator",
//     name: "Bryan Zetlen",
//     description:
//       "Bryan Zetlen is an experienced senior government program and technology manager, with an extensive background helping drive the success of complex technology/engineering initiatives for organizations around the globe. His portfolio ranges from startups to government agencies to Fortune 500 enterprises. Mr. Zetlen’s focus is on technology transfer and IP commercialization, with over 120 projects in the aerospace, engineering, biotechnology, energy, and manufacturing sectors. Bryan has served as a policy advisor for local/federal governments and International organisations including: the United Nations, the Organization of American States, and the Governments of China, United Kingdom, EU, Australia, Canada, Argentina, Slovakia, New Zealand, and Costa Rica.",
//   },
//   {
//     image: "/assets/images/team/ba.jpg",
//     designation: "Fintech and Blockchain Advisor",
//     name: "Joel Telpner",
//     description:
//       'Joel is a highly sought-after legal advisor in the fintech and blockchain space. Joel brings more than 30 years of legal experience in a career that includes time as an AmLaw 100 partner, the former U.S. general counsel of a global financial institution and a venture capitalist. Joel leads policy initiatives on global regulatory issues for blockchain on behalf of the Chamber of Digital Commerce, the Global Blockchain Business Council and the Blockchain Research Institute. He is also a member of the Wall Street Blockchain Alliance’s Legal Working Group, which is developing guidance on best practices for the blockchain industry. Joel led the discussion "ICOs – Best Practices" at the World Economic Forum’s Blockchain Central in Davos, Switzerland in January 2018. Joel was named as one of the Top 6 Fintech Leading Lawyers in the United States in The Legal 500 U.S. 2019 Edition; and one of the top 10 Fintech attorneys in America for 2019 by Chambers & Partners.',
//   },
// ];

// const renderImage = (imageUrl: string, name: string, designation: string) => (
//   <div className={styles.thumbnailContainer}>
//     <img className={`${styles.path} u-pos-rel`} src={iconImageBg} alt="" />
//     <img className={styles.memberImg} src={imageUrl} alt="" />
//   </div>
// );

// const renderTeam = (
//   <>
//     <h2>Team</h2>
//     <Row>
//       {team.map((member, i) => (
//         <Col key={i} sm={6} md={4} lg={3}>
//           <div className={`${styles.memberContainer} u-text-center u-margin-bottom_2`}>
//             {renderImage(member.image, member.name, member.designation)}
//             <h5>{member.designation}</h5>
//             <h4>{member.name}</h4>
//             <p>{member.description}</p>
//           </div>
//         </Col>
//       ))}
//     </Row>
//   </>
// );

const initialState = {
  email: "",
  name: "",
  message: "",
};

const Aboutus = () => {
  const [contactForm, setContactForm] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(initialState);

  const isFormValid = () => {
    const { email, name, message } = contactForm;
    const _errors: typeof contactForm = { ...errors };
    if (email.trim().length && name.trim().length && message.trim().length) {
      return true;
    }
    if (!email.trim().length) {
      _errors.email = "Please enter your email.";
    }
    if (!name.trim().length) {
      _errors.name = "Please enter your name.";
    }
    if (!message.trim().length) {
      _errors.message = "Please enter a valid message.";
    }
    setErrors(_errors);
    return false;
  };

  const sendMessage = () => {
    if (isFormValid()) {
      setIsLoading(true);
      firebase
        .database()
        .ref("/contacts")
        .push(contactForm)
        .then(() => {
          setIsLoading(false);
          setContactForm(initialState);
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <>
      <Banner
        title="Award Winning Team for Compliance Software"
        desc="Penta is a leading innovator of blockchain technologies and data privacy solutions."
        imgUrl="/assets/images/aboutus_header.png"
      />
      <section className={styles.content}>
        <Container>
          <Row>
            <Col md={6}>
              <div className={styles.formContainer}>
                <h2>Leave a message</h2>
                <TextField
                  type="email"
                  value={contactForm.email}
                  onChange={(e) => setContactForm({ ...contactForm, email: e.target.value })}
                  className="u-margin-bottom_1"
                  classes={{ root: styles.textarea }}
                  fullWidth
                  required
                  id="email"
                  label="Company Email"
                  error={Boolean(errors.email)}
                />
                <TextField
                  autoComplete="name"
                  value={contactForm.name}
                  onChange={(e) => setContactForm({ ...contactForm, name: e.target.value })}
                  fullWidth
                  required
                  id="name"
                  label="Full Name"
                  className="u-margin-bottom_1"
                  classes={{ root: styles.textarea }}
                  error={Boolean(errors.name)}
                />
                <TextField
                  value={contactForm.message}
                  onChange={(e) => setContactForm({ ...contactForm, message: e.target.value })}
                  fullWidth
                  multiline
                  required
                  id="message"
                  label="Message"
                  className="u-margin-bottom_1"
                  classes={{ root: styles.textarea }}
                  error={Boolean(errors.message)}
                />
                <Button isLoading={isLoading} onClick={sendMessage} className="u-margin-top_1">
                  Send Message
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Aboutus;
