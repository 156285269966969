import { Controller, Scene } from "react-scrollmagic";
import { NavLink, useLocation } from "react-router-dom";
import { Timeline, Tween } from "react-gsap";

import Button from "../Button";
import { ReactComponent as Logo } from "../../assets/icons/logo_tm.svg";
import MobileNav from "./MobileNav";
import { navlinks } from "./routes";
import styles from "./Navbar.module.scss";
import { useScreenClass } from "react-grid-system";
import { useState } from "react";

const Navbar = () => {
  const screenClass = useScreenClass();
  const location = useLocation();
  const [isScrolling, setIsScrolling] = useState(false);
  //throw out scrollMagic
  const navbarOnScroll = () => {
    const flag = window.scrollY >= 90 ? true : false;
    setIsScrolling(flag);
  };
  window.addEventListener("scroll", navbarOnScroll);

  const getNavLinks = (expandMenu?: Function) => {
    const handleClick = () => {
      if (expandMenu) {
        expandMenu(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    };

    return (
      <ul>
        {navlinks.map((link, i) => (
          <li key={i}>
            <NavLink exact to={link.to} activeClassName={styles.home} onClick={handleClick}>
              {link.name}
            </NavLink>
          </li>
        ))}
      </ul>
    );
  }; //eo navLinks fcn component

  const navLinks = getNavLinks();

  if (["xs", "sm", "md"].includes(screenClass)) {
    return (
      <MobileNav
        expandedHeight={navlinks.length * 48 + 86}
        render={(expandMenu) => getNavLinks(expandMenu)}
      />
    );
  }

  const wrapperClassName = ["/privacy-policy"].includes(location.pathname) ? "bg-white" : "";

  return (
    <div
      className={
        isScrolling
          ? `${styles.wrapperScrolling} ${wrapperClassName}`
          : `${styles.wrapper} ${wrapperClassName}`
      }
    >
      <Controller>
        <Scene triggerHook="onLeave" duration={300}>
          {(progress: number) => (
            <div className={styles.header}>
              <div className={styles.navLogo}>
                <div className="u-flex-centered">
                  <NavLink to="/" className={`u-flex-centered ${styles.homeLink}`}>
                    <Logo
                      className={`${styles.logo} ${progress < 0.35 ? styles.expandedNav : ""}`}
                    />
                  </NavLink>
                </div>
              </div>
              <div className={styles.navLinks}>{navLinks}</div>
            </div>
          )}
        </Scene>
      </Controller>
    </div>
  );
};

export default Navbar;
