export type DataView = {
  url: string,
  query?: any,
  method?: 'POST' | 'GET',
  data?: any,
};

export const getSendEmailConfig = (params: any): DataView => ({
  url: '/sendmail',
  method: 'POST',
  data: params,
});
