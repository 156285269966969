import { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Power3, TweenMax } from 'gsap';

import logo from "../../assets/icons/logo_tm.svg";
import styles from './MobileNav.module.scss';
import menuIcon from '../../assets/icons/menu.svg';

interface Props {
  expandedHeight: number;
  render: (func: Function) => JSX.Element;
}

const MobileNav = ({ render, expandedHeight }: Props): JSX.Element => {
  const nav = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();

  const handelExpand = () => {
    if (isExpanded === false) {
      TweenMax.to(nav.current, 0.8, { height: expandedHeight, ease: Power3.easeOut });
      setIsExpanded(true);
    } else {
      TweenMax.to(nav.current, 0.8, { height: 0, ease: Power3.easeOut });
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    TweenMax.to(nav.current, 0.8, { height: 0, ease: Power3.easeOut });
    setIsExpanded(false);
  }, [location.pathname]);

  return (
      <div className={styles.MoNavContainer}>
        <div className={styles.mobileNav}>
          <div className={styles.navBars}>
            <img src={menuIcon} alt="Menu" className={styles.menu} onClick={handelExpand} />
          </div>
          <div className={styles.MoNavLogo}>
            <NavLink to="/" className={styles.homeLink} onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
              <img src={logo} alt="logo" />
            </NavLink>
          </div>
        </div>
        <div className={styles.MoNavLinks} ref={nav}>
          {render(handelExpand)}
        </div>
      </div>
  );
};

export default MobileNav;
