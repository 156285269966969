import { Col, Container, Hidden, Row } from "react-grid-system";

import Button from "../../components/Button";
import CookieConsent from "react-cookie-consent";
// @ts-ignore
import Fade from "react-reveal/Fade";
import Features from "./Features";
import FineCalculator from "./FineCalculator";
import ScrollMenu from "react-horizontal-scrolling-menu";
import Tiles from "./Tiles";
import constants from "../../utils/constants";
import icon1 from "../../assets/icons/icon_tile1.svg";
import icon2 from "../../assets/icons/icon_tile2.svg";
import icon3 from "../../assets/icons/icon_tile3.svg";
import icon4 from "../../assets/icons/icon_tile4.svg";
import iconAICPA from "../../assets/icons/icon_aicpa.png";
import iconCSA from "../../assets/icons/icon_csa.png";
import iconCT from "../../assets/icons/icon_cointelegraph.svg";
import iconCnbc from "../../assets/icons/icon_cnbc.svg";
import iconCnn from "../../assets/icons/icon_cnn.svg";
import iconCoindesk from "../../assets/icons/icon_coindesk.svg";
import iconHLN from "../../assets/icons/icon_hln.svg";
import iconHbr from "../../assets/icons/icon_hbr.svg";
import iconHyperledger from "../../assets/icons/icon_hyperledger.svg";
import iconIAPP from "../../assets/icons/icon_iapp.png";
import iconIEEE from "../../assets/icons/icon_ieee.png";
import iconRight from "../../assets/icons/home_page_icon_2.svg";
import iconYahoo from "../../assets/icons/icon_yahoo_finance.svg";
import iconfox from "../../assets/icons/icon_fox_business.svg";
import styles from "./styles.module.scss";

const iconsData = [
  {
    icon: icon1,
    text: "Reduce Compliance Risk",
  },
  {
    icon: icon2,
    text: "Avoid Hefty Fines",
  },
  {
    icon: icon3,
    text: "Automate Data \n Privacy Management",
  },
  {
    icon: icon4,
    text: "Protect Customer Privacy",
  },
];

const newsIcons = [
  iconCnbc,
  iconHbr,
  iconfox,
  iconCoindesk,
  iconCnn,
  iconHLN,
  iconYahoo,
  iconCT,
  iconHyperledger,
];

const cerificationIcons = [iconIAPP, iconIEEE, iconAICPA, iconCSA];

const generateUID = (): string => {
  // I generate the UID from two parts here
  // to ensure the random number provide enough bits.
  let firstPart: any = (Math.random() * 46656) | 0;
  let secondPart: any = (Math.random() * 46656) | 0;
  firstPart = ("000" + firstPart.toString(36)).slice(-3);
  secondPart = ("000" + secondPart.toString(36)).slice(-3);
  return firstPart + secondPart;
};
const brandContainer = (
  <div className={styles.brandContainer} key={generateUID()}>
    {Array(5)
      .fill(true)
      .map((_, idx) => (
        <span
          key={generateUID()}
          className={`${styles.trapezoid} ${styles[`tr${(idx + 1).toString()}`]}`}
        />
      ))}
    <div className={styles.logosContainer}>
      <h5>As Seen On</h5>
      <Container fluid>
        <Row nogutter justify="between">
          <Col
            xs={12}
            lg={4}
            style={{ justifyContent: "space-around", display: "flex", marginBottom: "2.5em" }}
          >
            {newsIcons.slice(0, 3).map((iconSrc) => (
              <img key={iconSrc} src={iconSrc} alt="icon" className={styles.newsLogo} />
            ))}
          </Col>
          <Col
            xs={12}
            lg={4}
            style={{ justifyContent: "space-around", display: "flex", marginBottom: "2.5em" }}
          >
            {newsIcons.slice(3, 6).map((iconSrc) => (
              <img key={iconSrc} src={iconSrc} alt="icon" className={styles.newsLogo} />
            ))}
          </Col>
          <Col
            xs={12}
            lg={4}
            style={{ justifyContent: "space-around", display: "flex", marginBottom: "2.5em" }}
          >
            {newsIcons.slice(6).map((iconSrc) => (
              <img key={iconSrc} src={iconSrc} alt="icon" className={styles.newsLogo} />
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  </div>
);

// const blockChainContent = (
//   <div className={styles.brandContainer}>
//     {Array(5)
//       .fill(true)
//       .map((_, idx) => (
//         <span
//           key={idx}
//           className={`${styles.trapezoid} ${styles[`tr_white${(idx + 1).toString()}`]}`}
//         />
//       ))}
//     <div className={`${styles.logosContainer} ${styles.bHeading}`}>
//       <h2>BLOCKCHAIN AS A PRIVACY TOOL</h2>
//       <div className="u-text-center">
//         <Button variant="fancy">Read about Blockchain</Button>
//       </div>
//     </div>
//   </div>
// );

const certifications = (
  <div className={styles.certification}>
    <Container style={{ backgroundColor: "#f0edf4" }}>
      <ScrollMenu
        data={cerificationIcons.map((icon, i) => (
          <img className={styles.certLogo} src={icon} key={i} alt="i" />
        ))}
        arrowLeft={<span>{"<"}</span>}
        arrowRight={<span>{">"}</span>}
        arrowClass={styles.arrowClass}
        arrowDisabledClass={styles.arrowDisabledClass}
        scrollToSelected
        translate={0}
        wrapperClass={styles.wrapperClass}
        innerWrapperClass={styles.innerWrapperClass}
      />
    </Container>
  </div>
);

const Home = () => {
  let cookieConsentSpanStyle = {
    display: "block",
    textAlign: "justify",
    cursor: "pointer",
    webkitUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
  };
  if (window.screen.width < 768) {
    cookieConsentSpanStyle.textAlign = "left";
  }
  return (
    <>
      <Container>
        <Row className={styles.firstSection} align="center" nogutter>
          <Col xs={12} md={5}>
            <section className={styles.leftCol}>
              <div>
                <Fade bottom>
                  <h1>
                    CCPA Compliance <br /> Made Easy
                  </h1>
                </Fade>
                <p className={styles.para}>
                  Automate and simplify data privacy compliance. <br />
                  Powered by Penta Network’s award winning blockchain technology.
                </p>
              </div>
            </section>
          </Col>
          <Col xs={12} md={7}>
            <div className="u-pos-rel">
              <div className={styles.priceCol}>
                <div>
                  <Fade bottom cascade>
                    <h3>
                      <Fade bottom>Prices start as low as</Fade>
                    </h3>
                    <span className={styles.priceTagContainer}>
                      <span className={`bg-theme color-white ${styles.tag}`}>$50/ month</span>
                      <Hidden sm xs>
                        <img className={styles.iconRight} src={iconRight} alt="" />
                      </Hidden>
                    </span>
                  </Fade>
                </div>
              </div>
              <img src="/assets/images/ipad_and_mobile.png" alt="ipad" className={styles.ipadImg} />
            </div>
          </Col>
        </Row>
      </Container>
      {brandContainer}
      <div className={styles.highlightContainer}>
        <Container>
          <div className="u-text-center u-margin-bottom_2">
            <Fade bottom>
              <h1 className="u-mt-zero u-margin-bottom_1">
                Your Complete Turnkey CCPA Compliance Solution
              </h1>
            </Fade>
            <Fade bottom>
              <h2 className="u-mt-zero">
                Privacy Lock helps you protect your business and your consumers with patent-pending
                privacy technology.
              </h2>
            </Fade>
          </div>
          <Fade bottom opposite big cascade>
            <Row>
              <Tiles iconsData={iconsData} />
            </Row>
          </Fade>
        </Container>
      </div>
      <Features />
      <Container className={styles.spaced}>
        <Row align="center">
          <Col xs={12} md={5}>
            <div>
              <Fade bottom>
                <h1 className="u-mt-zero">
                  Automate & Operationalize Your Data Privacy Compliance Today!
                </h1>
              </Fade>
              <Fade bottom>
                <p className={`u-text-justify ${styles.para}`}>
                  Penta Privacy Lock delivers on compliance. Give your business the tools to manage
                  consumer information responsibly and to avoid fines.
                </p>
              </Fade>
            </div>
          </Col>
          <Col xs={12} md={7}>
            <img
              className={`u-full-width ${styles.ipad2}`}
              src="/assets/images/ipad_and_mobile.png"
              alt="ipad"
            />
          </Col>
        </Row>
      </Container>
      {certifications}
      <FineCalculator />
      <CookieConsent
        location="bottom"
        buttonText="Privacy Policy"
        cookieName="myAwesomeCookieName2"
        style={{
          flexDirection: "column",
          alignItems: "flex-end",
          maxWidth: "700px",
          marginBottom: "50px",
          background: "#8765b3",
          color: "#ffffff",
        }}
        contentStyle={{
          flex: "1 0",
          fontSize: "18px",
          padding: "7px",
          margin: "15px",
          marginBottom: "5px",
        }}
        buttonStyle={{ background: "#a287c4", color: "#ffffff", fontSize: "18px" }}
        expires={1}
        onAccept={() => {
          window.open("https://www.pentaprivacylock.com/privacy-policy", "_blank");
        }}
      >
        <span style={cookieConsentSpanStyle}>
          Privacy Lock believes in your right to privacy! Only strictly necessary cookies are
          collected, to help when browsing this website. User information is never shared or sold,
          and we do not collect or store other personal information.
        </span>
      </CookieConsent>
    </>
  );
};

export default Home;
