const constants = {
  calendlyConfig: {
    url: "https://calendly.com/pentanetwork/30min",
    pageSettings: {
      backgroundColor: "7450a3",
      hideEventTypeDetails: false,
      hideLandingPageDetails: false,
      primaryColor: "fff",
      textColor: "fff",
    },
  },
  regex: {
    emailRegex: new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
    blacklistedEmailRegex: new RegExp(/(aol|gmail|yahoo|hotmail|outlook)\.com$/),
    nameRegex: new RegExp(/^[a-zA-Z]+ [a-zA-Z]+$/),
  },
};

export default constants;
