import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Modal from "../../components/Modal";
import tickIcon from "../../assets/icons/icon_tick_filled.svg";

const Acknowledge = () => {
  const history = useHistory();

  const closeModal = () => {
    history.replace({ state: { background: undefined }});
    history.goBack();
  };

  useEffect(() => {
    setTimeout(closeModal, 3000);
  }, []);

  return (
    <Modal
      open
      onClose={closeModal}
      aria-labelledby="acknowledge-message"
      style={{ zIndex: 9999 }}
    >
      <div className="padding-vertical_std padding-horizontal_std u-text-center">
        <img src={tickIcon} alt="tick" style={{ width: '5em', marginBottom: '1em'}} />
        <h2>Thank you for scheduling a demo.</h2>
        <h3>Your friends at Penta</h3>
      </div>
    </Modal>
  );
};

export default Acknowledge;
