import { Col, Container, Hidden, Row } from "react-grid-system";
import { FormEvent, useRef, useState } from "react";
import { GAEventCategory, trackEvent } from "../../utils/analytics";
import { formatPrice, openCalendly } from "../../utils";

import Button from "../../components/Button";
// @ts-ignore
import Fade from "react-reveal/Fade";
import Input from "../../components/Input";
import ReactCardFlip from "react-card-flip";
import Select from "../../components/Select";
// @ts-ignore
import Shake from "react-reveal/Shake";
// @ts-ignore
import Slide from "react-reveal/Slide";
import Tooltip from "@material-ui/core/Tooltip";
import constant from "../../utils/constants";
import { fetchData } from "../../utils/api";
import firebase from "../../utils/firebase";
import { getSendEmailConfig } from "../../utils/apiConfig";
import iconInfo from "../../assets/icons/icon_info.svg";
import styles from "./FineCalculator.module.scss";

type Calculated = {
  "Consumer Action": { low: number; high: number };
  "State Action": { low: number; high: number };
};

const recordRange = [
  {
    label: "10,000",
    value: 10000,
  },
  {
    label: "100,000",
    value: 100000,
  },
  {
    label: "1 Million",
    value: 1000000,
  },
  {
    label: "Custom",
    value: "custom",
  },
];

const privacyActs = ["CCPA"];

const privacyActsConfig = {
  CCPA: {
    "State Action": {
      min_bound: 2500,
      max_bound: 7500,
    },
    "Consumer Action": {
      min_bound: 250,
      max_bound: 750,
    },
  },
};

const initialState = {
  name: "",
  email: "",
  company: "",
  act: privacyActs[0],
  records: 10000,
  customRecords: "",
  location: "home",
};

type State = {
  name: string;
  email: string;
  company: string;
  act: string;
  records: string | number;
  customRecords: string;
  location: string;
};

const { emailRegex, blacklistedEmailRegex, nameRegex } = constant.regex;

const FineCalculator = () => {
  const [state, setState] = useState<State>(initialState);
  const [acts] = useState<Array<string>>(privacyActs);
  const [calculated, setCalculated] = useState<Calculated>();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage] = useState("");
  const [errors, setErrors] = useState(initialState);
  const resultRef = useRef<HTMLDivElement>(null);

  const handleChange = (name: string, value: string) => {
    setState({ ...state, [name]: value });
  };

  const getCalculated = (act: any, records: string) => {
    const calc: Calculated = {
      "State Action": {
        low: privacyActsConfig[act]["State Action"]["min_bound"] * parseInt(records),
        high: privacyActsConfig[act]["State Action"]["max_bound"] * parseInt(records),
      },
      "Consumer Action": {
        low: privacyActsConfig[act]["Consumer Action"]["min_bound"] * parseInt(records),
        high: privacyActsConfig[act]["Consumer Action"]["max_bound"] * parseInt(records),
      },
    };
    setCalculated(calc);
    setIsLoading(false);
  };

  const isFormValid = () => {
    const { email, name, company } = state;
    const _errors = { ...initialState };
    if (!emailRegex.test(email) || blacklistedEmailRegex.test(email)) {
      _errors.email = "Please enter your company email.";
    }
    if (!name.trim().length || !nameRegex.test(name)) {
      _errors.name = "Please enter your full name.";
    }
    if (!company.trim().length) {
      _errors.company = "Please enter your company name.";
    }
    setErrors(_errors);
    if (_errors.name || _errors.company || _errors.email) {
      return false;
    }
    return true;
  };

  const calculate = (e: FormEvent) => {
    e.preventDefault();
    if (isFormValid()) {
      if (resultRef) {
        resultRef.current?.scrollIntoView({ behavior: "smooth" });
      }
      setIsLoading(true);
      firebase.database().ref("/fine_calculator").push(state);
      fetchData(
        getSendEmailConfig({ email: state.email, contentKey: "fine-calculator", name: state.name })
      );
      trackEvent(GAEventCategory.CALCULATE_FINE, "click", "calculated fine", state.records);
      if (state.records) {
        if (state.records === "custom" && state.customRecords) {
          return getCalculated(state.act, state.customRecords);
        }
        return getCalculated(state.act, state.records.toString());
      }
    }
  };

  const stateActionHeader = (
    <h4>
      State Action
      <Tooltip title="When the state of California takes action against a business entity for violating the terms of the CCPA.">
        <img src={iconInfo} alt="i" />
      </Tooltip>
    </h4>
  );

  const consumerActionHeader = (
    <h4>
      Consumer Action
      <Tooltip title="When a California resident takes action against a business entity for violating the terms of the CCPA.">
        <img src={iconInfo} alt="i" />
      </Tooltip>
    </h4>
  );

  return (
    <Container fluid className={styles.container}>
      <Row nogutter align="stretch">
        <Col md={6} xs={12}>
          <div className={styles.leftCol}>
            <h1>
              <Slide bottom cascade>
                CCPA Fine Calculator
              </Slide>
            </h1>
            <Slide bottom>
              <p className={styles.desc}>
                Find out how much your business might have to pay in CCPA fines for compliance
                violations. Just one violation could reach $7,500 per record!
              </p>
            </Slide>
            <Slide bottom cascade>
              <form className={styles.form} onSubmit={calculate}>
                <Input
                  label="Full Name"
                  onChange={(value) => handleChange("name", value)}
                  value={state.name}
                />
                <Shake spy={errors.name}>
                  <h6 className="u-no-margin input-error">{errors.name}</h6>
                </Shake>
                <Input
                  label="Email"
                  type="email"
                  onChange={(value) => handleChange("email", value)}
                  value={state.email}
                />
                <Shake spy={errors.email}>
                  <h6 className="u-no-margin input-error">{errors.email}</h6>
                </Shake>
                <Input
                  label="Company Name"
                  onChange={(value) => handleChange("company", value)}
                  value={state.company}
                />
                <Shake spy={errors.company}>
                  <h6 className="u-no-margin input-error">{errors.company}</h6>
                </Shake>
                <Select
                  options={acts.map((act) => ({ label: act, value: act }))}
                  label="Act"
                  onChange={(value) => handleChange("act", value)}
                  value={state.act}
                />
                <Select
                  options={recordRange}
                  label="Number of Records"
                  onChange={(value) => handleChange("records", value)}
                  value={state.records}
                />
                <Fade top collapse when={state.records === "custom"} className="u-margin-bottom_1">
                  <Input
                    label="Records"
                    type="number"
                    onChange={(value) => {
                      if (!(Number(value) < 0)) {
                        handleChange("customRecords", value);
                      }
                    }}
                    value={state.customRecords}
                    className="u-margin-bottom_1"
                  />
                </Fade>
                <Button
                  type="submit"
                  isLoading={isLoading}
                  className="u-margin-top_1 u-full-width"
                  variant="fancy"
                >
                  Start calculating
                </Button>
                {errorMessage && (
                  <Fade top collapse when={!!errorMessage} className="u-margin-bottom_1">
                    <p className="u-margin-top_1">{errorMessage}</p>
                  </Fade>
                )}
              </form>
            </Slide>
          </div>
        </Col>
        <Col md={6} xs={12} style={{ backgroundColor: "white" }}>
          <Row nogutter align="stretch" className="u-full-height">
            <Hidden xs sm>
              <Col md={2}>
                <div className={styles.arrowContainer}>
                  <span className={styles.arrowTop} />
                  <span className={styles.arrowBottom} />
                </div>
              </Col>
            </Hidden>
            <Col md={10} xs={12}>
              <section className={styles.rightCol} ref={resultRef}>
                <div className="u-full-width">
                  <h1>You Might Owe</h1>
                  <Row>
                    <Col>
                      <ReactCardFlip
                        isFlipped={calculated && !isLoading}
                        flipDirection="horizontal"
                      >
                        <div className={styles.card}>{stateActionHeader}</div>
                        <div className={styles.card}>
                          {stateActionHeader}
                          <h6>Range (lowest to highest)</h6>
                          {calculated && (
                            <div className={styles.priceContainer}>
                              {formatPrice(calculated["State Action"]?.low)}
                              <br />
                              &mdash;
                              <br />
                              {formatPrice(calculated["State Action"]?.high)}
                            </div>
                          )}
                        </div>
                      </ReactCardFlip>
                    </Col>
                    <Col>
                      <ReactCardFlip
                        isFlipped={calculated && !isLoading}
                        flipDirection="horizontal"
                      >
                        <div className={styles.card}>{consumerActionHeader}</div>
                        <div className={styles.card}>
                          {consumerActionHeader}
                          <h6>Range (lowest to highest)</h6>
                          {calculated && (
                            <div className={styles.priceContainer}>
                              {formatPrice(calculated["Consumer Action"]?.low)}
                              <br />
                              &mdash;
                              <br />
                              {formatPrice(calculated["Consumer Action"]?.low)}
                            </div>
                          )}
                        </div>
                      </ReactCardFlip>
                    </Col>
                  </Row>
                  <h2>Comply Today and Avoid Fines</h2>
                </div>
              </section>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default FineCalculator;
