const featureList = [
  {
    title: "Data Inventory Management",
    label: "Manage your data inventory",
    description: "Penta Privacy Lock helps you track what information you have collected on your customers, so you can comply with data management requirements.",
    image: "/assets/images/data_inventory_management.svg",
  },
  {
    title: "Data Mapping",
    description: <>Penta Privacy Lock uses proprietary data mapping software to tag and track consumer information in your databases  - <span className="u-font-strong">without disrupting business operations!</span> Track consumer information between offices, with vendors, and across borders to ensure robust privacy compliance with Penta Privacy Lock.</>,
    image: "/assets/images/data_mapping.svg",
  },
  {
    title: "Consumer Requests",
    label: "Consumer Request Tracking",
    description: "Track consumer request processes and automate request handling. Penta Privacy Lock does the heavy lifting so that you can avoid manual data input to satisfy consumer request processes.",
    srcSet: ["/assets/images/consumer_request_tracking_640.jpg 640w", "/assets/images/consumer_request_tracking_1280.jpg 1280w", "/assets/images/consumer_request_tracking_2770.jpg 2770w"],
    whiteSrcSet: ["/assets/images/consumer_request_tracking_640-white.jpg 640w", "/assets/images/consumer_request_tracking_1280-white.jpg 1280w", "/assets/images/consumer_request_tracking_2770-white.jpg 2770w"],
  },
  {
    title: "Notiﬁcation Center",
    description: "Manage notices to consumers and updated privacy agreements with our easy-to-use Notification Center.",
    image: "/assets/images/notification_center.svg",
  },
  {
    title: "Vendor Management",
    description: "Track what data you send to vendors and make sure you can comply fully with consumer requests. CCPA compliance extends to vendor relationships, and that is why Penta makes it easy to track and manage data interactions with vendors.",
    srcSet: ["/assets/images/vendor_management_640.jpg 640w", "/assets/images/vendor_management_1280.jpg 1280w", "/assets/images/vendor_management_2770.jpg 2770w"],
    whiteSrcSet: ["/assets/images/vendor_management_640-white.jpg 640w", "/assets/images/vendor_management_1280-white.jpg 1280w", "/assets/images/vendor_management_2770-white.jpg 2770w"],
  },
  {
    title: "Consent Management",
    description: "Implement consumer consent requests in real-time. You can automate consent management processes with Penta Privacy Lock.",
    image: "/assets/images/consent_management.svg",
  },
  {
    title: "Auditing & Reporting",
    description: "Audit and report your data privacy activities in real-time with Penta’s blockchain verified data certificates. Build trust between your business and your customers with Penta Privacy Lock.",
    srcSet: ['/assets/images/auditing_reporting_640.jpg 640w', '/assets/images/auditing_reporting_1280.jpg 1280w', '/assets/images/auditing_reporting_2770.jpg 2770w'],
    whiteSrcSet: ['/assets/images/auditing_reporting_640-white.jpg 640w', '/assets/images/auditing_reporting_1280-white.jpg 1280w', '/assets/images/auditing_reporting_2770-white.jpg 2770w'],
  },
  {
    title: "Blockchain Certificate",
    description: "Blockchain certificates provide a robust audit trail and time stamps for data interactions that involve consumer information. Use blockchain certificates to demonstrate compliance with high data integrity to consumers and regulators.",
    image: "/assets/images/blockchain_certificate.svg",
  }
];

export const getFeatureList = () => new Map(featureList.map(f => [f.title, f]));
export default getFeatureList;
