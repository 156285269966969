import React, { useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import { Carousel } from "react-responsive-carousel";

import Banner from "../../components/Banner";
import Whitepaper from "../../components/Whitepaper";
import styles from "./styles.module.scss";
import { ReactComponent as IconRight } from "../../assets/icons/icon_arrow_right.svg";
import { ReactComponent as IconLeft } from "../../assets/icons/icon_arrow_left.svg";
import Button from "../../components/Button";
import { openCalendly } from "../../utils";

import bankingIcon from "../../assets/icons/icon_banking.png";
import paymentIcon from "../../assets/icons/icon_payment processing.png";
import realEstateIcon from "../../assets/icons/icon_real_estate.png";

// Grouping for ease in rendering two items per slide
const testimonials = [
  [
    {
      author: "Full Name",
      title: "Quick & Efficient",
      subtitle: "VP of ISSAI",
      description:
        "Powered By blockchain, Penta Privacy Lock automates compliance  and helps you operationalize all the factors of compliance while making sure all the legalities are up to the markLorem ipsum dolor sit amet, consectetuer adipisc-ing elit, sed diam nonummy nibh euismod tincidunt ut ",
      imageUrl: "",
    },
    {
      author: "Full Name",
      title: "Reasonable & Easy to use",
      subtitle: "VP of ISSAI",
      description:
        "Powered By blockchain, Penta Privacy Lock automates compliance  and helps you operationalize all the factors of compliance while making sure all the legalities are up to the markLorem ipsum dolor sit amet, consectetuer adipisc-ing elit, sed diam nonummy nibh euismod tincidunt ut ",
      imageUrl: "",
    },
  ],
  [
    {
      author: "Full Name",
      title: "Quick & Efficient",
      subtitle: "VP of ISSAI",
      description:
        "Powered By blockchain, Penta Privacy Lock automates compliance  and helps you operationalize all the factors of compliance while making sure all the legalities are up to the markLorem ipsum dolor sit amet, consectetuer adipisc-ing elit, sed diam nonummy nibh euismod tincidunt ut ",
      imageUrl: "",
    },
  ],
];

const usecases = [
  [
    {
      author: "Full Name",
      title: "Quick & Efficient",
      subtitle: "VP of ISSAI",
      description:
        "Powered By blockchain, Penta Privacy Lock automates compliance  and helps you operationalize all the factors of compliance while making sure all the legalities are up to the markLorem ipsum dolor sit amet, consectetuer adipisc-ing elit, sed diam nonummy nibh euismod tincidunt ut ",
      imageUrl: "../../assets/images/icon_usecase_1.png",
    },
    {
      author: "Full Name",
      title: "Reasonable & Easy to use",
      subtitle: "VP of ISSAI",
      description:
        "Powered By blockchain, Penta Privacy Lock automates compliance  and helps you operationalize all the factors of compliance while making sure all the legalities are up to the markLorem ipsum dolor sit amet, consectetuer adipisc-ing elit, sed diam nonummy nibh euismod tincidunt ut ",
      imageUrl: "../../assets/images/icon_usecase_2.png",
    },
  ],
  [
    {
      author: "Full Name",
      title: "Reasonable & Easy to use",
      subtitle: "VP of ISSAI",
      description:
        "Powered By blockchain, Penta Privacy Lock automates compliance  and helps you operationalize all the factors of compliance while making sure all the legalities are up to the markLorem ipsum dolor sit amet, consectetuer adipisc-ing elit, sed diam nonummy nibh euismod tincidunt ut ",
      imageUrl: "../../assets/images/icon_usecase_2.png",
    },
  ],
];

const UseCases = () => {
  const [selectedSlideIndex, setSelectedSlideIndex] = useState(0);
  const [selectedUsecaseIndex, setSelectedUsecaseIndex] = useState(0);

  const renderCard = (
    author: string,
    title: string,
    subTitle: string,
    description: string,
    image?: string,
    hasShadow?: boolean
  ): React.ReactNode => (
    <div className={`${styles.card} ${hasShadow ? styles.shadow : ""} u-flex`}>
      <div className={styles.imageWrapper}>
        {image ? <img src={image} alt="Author" /> : <div className={styles.imageFallback} />}
      </div>
      <div className={`${styles.cardContent} u-text-left`}>
        <h5 className="u-no-margin">{author}</h5>
        <h2>{title}</h2>
        <h5 className="u-no-margin">{subTitle}</h5>
        <p>{description}</p>
      </div>
    </div>
  );

  const bannerImageContent = (
    <div className={`${styles.bannerImageContent} u-text-center`}>
      <div>
        <img src={bankingIcon} style={{ width: '10em'}} className={styles.usecaseIcon} alt="Banking" />
        <h6>Banking</h6>
      </div>
      <div className={`${styles.bottomIcons} u-flex`}>
      <span>
        <img src={realEstateIcon} style={{ width: '7em'}} className={styles.usecaseIcon} alt="Real Estate" />
        <h6>Real Estate</h6>
      </span>
      <span>
        <img src={paymentIcon} style={{ width: '9em'}} className={styles.usecaseIcon} alt="Payment Processing" />
        <h6>Payment Processing</h6>
      </span>
      </div>
    </div>
  );

  return (
    <>
      <Banner
        title="Client & Industry Use Cases"
        desc={
          <>
            <p className="u-letter-spacing-std u-line-height-std">
              Penta Privacy Lock was built to handle a diverse variety of different use cases.
              Please inquire today to see how Penta Privacy Lock can operationalize your compliance
              today.
            </p>
            <Button onClick={openCalendly}>
              Enquire Now
            </Button>
          </>
        }
        contentCenter={bannerImageContent}
        className={styles.bannerContainer}
      />
      <main className="bg-white">
        <div className={`${styles.content} u-margin-bottom_2`}>
          <Container>
            <header className={`${styles.header} u-flex u-margin-bottom_1`}>
              <h2>Testimonials</h2>
              <div>
                <button
                  onClick={() => setSelectedSlideIndex((idx) => idx - 1)}
                  className="textual-button u-margin-right_1"
                >
                  <IconLeft className={styles.arrow} />
                </button>
                <button
                  onClick={() => setSelectedSlideIndex((idx) => idx + 1)}
                  className="textual-button"
                >
                  <IconRight className={styles.arrow} />
                </button>
              </div>
            </header>
            <Carousel
              showArrows={false}
              // autoPlay
              infiniteLoop
              selectedItem={selectedSlideIndex}
              showIndicators={false}
              showStatus={false}
              onChange={(index) => setSelectedSlideIndex(index)}
              showThumbs={false}
              // dynamicHeight
            >
              {testimonials.map((tesRow, i) => (
                <Row key={i}>
                  {tesRow.map((tes, i) => (
                    <Col sm={6} key={i}>
                      {renderCard(
                        tes.author,
                        tes.title,
                        tes.subtitle,
                        tes.description,
                        tes.imageUrl
                      )}
                    </Col>
                  ))}
                </Row>
              ))}
            </Carousel>
          </Container>
        </div>
        <div className={`${styles.content} ${styles.usecaseContent}`}>
          <Container>
            <header className={`${styles.header} u-flex u-margin-bottom_1`}>
              <h2>Use Cases</h2>
              <div>
                <button
                  onClick={() => setSelectedUsecaseIndex((idx) => idx - 1)}
                  className="textual-button"
                >
                  <IconLeft className={styles.arrow} />
                </button>
                <button
                  onClick={() => setSelectedUsecaseIndex((idx) => idx + 1)}
                  className="textual-button"
                >
                  <IconRight className={styles.arrow} />
                </button>
              </div>
            </header>
            <Carousel
              showArrows={false}
              autoPlay
              infiniteLoop
              selectedItem={selectedUsecaseIndex}
              showIndicators={false}
              showStatus={false}
              onChange={(index) => setSelectedUsecaseIndex(index)}
              showThumbs={false}
            >
              {usecases.map((usecaseRow, i) => (
                <Row key={i}>
                  {usecaseRow.map((usecass, idx) => (
                    <Col sm={6} className="u-margin-bottom_2" key={`${i}-${idx}`}>
                      {renderCard(
                        usecass.author,
                        usecass.title,
                        usecass.subtitle,
                        usecass.description,
                        usecass.imageUrl,
                        true
                      )}
                    </Col>
                  ))}
                </Row>
              ))}
            </Carousel>
          </Container>
        </div>
      </main>
      <Whitepaper />
    </>
  );
};

export default UseCases;
