import { Col, Container, Hidden, Row, useScreenClass } from "react-grid-system";
import { Fragment, useEffect } from "react";
import { RouteChildrenProps, useHistory } from "react-router";
import { convertToKebabCase, openCalendly } from "../../utils";

import AnchorLink from "../../components/AnchorLink";
import Button from "../../components/Button";
// @ts-ignore
import Fade from "react-reveal/Fade";
import LightboxImage from "../../components/LightboxImage";
import ScrollMenu from "react-horizontal-scrolling-menu";
// @ts-ignore
import Slide from "react-reveal/Slide";
import { getFeatureList } from "./featureData";
import styles from "./styles.module.scss";

const SCROLL_OFFSET = 80; // Navbar height

const featureData = getFeatureList();

featureData
  .set("Blockchain Certificate", {
    title: "Blockchain Certificate",
    description:
      "Blockchain certificates provide a robust audit trail and time stamps for data interactions that involve consumer information. Use blockchain certificates to demonstrate compliance with high data integrity to consumers and regulators. Penta Privacy Lock delivers trusted data solutions using blockchain technology.",
    image: "/assets/images/blockchain_certificate.svg",
  })
  .set("Data Inventory Management", {
    title: "Data Inventory",
    label: "Manage your data inventory",
    description:
      "Penta Privacy Lock helps you track what information you have collected on your customers, so you can comply with data management requirements.",
    image: "/assets/images/data_inventory_management.svg",
  });

const featureList = Array.from(featureData.values());

const Features = (props: RouteChildrenProps) => {
  const history = useHistory();
  const screenClass = useScreenClass();

  useEffect(() => {
    const sectionId = props.location.hash.slice(1);
    const anchor = document.getElementById(sectionId);
    if (sectionId && anchor) {
      const offsetTop = anchor.getBoundingClientRect().top + window.pageYOffset;
      window.scroll({
        top: offsetTop - SCROLL_OFFSET,
        behavior: "smooth",
      });
    }
  }, []);

  const renderFeatureTabs = () => {
    return featureList.map((feature) => {
      const hash = `#${convertToKebabCase(feature.title)}`;
      const isSelected = props.location.hash.slice(1) === hash.slice(1);
      return (
        <div
          key={feature.title}
          className={`${styles.featMenu} ${isSelected ? styles.selectedTab : ""}`}
        >
          <AnchorLink onClick={() => history.push(hash)} offset={SCROLL_OFFSET} href={hash}>
            <h3>{feature.title}</h3>
          </AnchorLink>
        </div>
      );
    });
  };

  const selectedTabIndex = featureList.findIndex((feature) => {
    const hash = `#${convertToKebabCase(feature.title)}`;
    return props.location.hash.slice(1) === hash.slice(1);
  });

  return (
    <>
      <div className={styles.bannerWrapper}>
        <Row align="center" className={styles.bannerContainer}>
          <Col
            xs={12}
            md={7}
            lg={5}
            style={{ position: "static", justifyContent: "space-between" }}
          >
            <section className={styles.leftCol}>
              <div className="u-letter-spacing-std">
                <Fade bottom>
                  <h2 className="u-margin-bottom_2">
                    <span>Automation is Key to Data Privacy</span>
                    <br />
                    <br />
                    Penta Privacy Lock is the #1 Compliance Toolkit for Data Privacy, secured with
                    Blockchain Technology
                  </h2>
                </Fade>
                <h3 className="u-line-height-std">
                  Blockchain is accounting technology that enables trust and auditability for data
                  privacy management. Learn how the Penta blockchain-powered toolkit automates
                  privacy compliance for Enterprise.
                </h3>
              </div>
            </section>
          </Col>

          <Hidden xs sm>
            <Col md={6} lg={7} style={{ alignSelf: "stretch" }}>
              <div className={styles.colRightWrapper} />
            </Col>
          </Hidden>
        </Row>
      </div>
      <section className={`${styles.featTab} bg-theme`}>
        <ScrollMenu
          data={renderFeatureTabs()}
          arrowLeft={<span>{"<"}</span>}
          arrowRight={<span>{">"}</span>}
          arrowClass={styles.arrowClass}
          arrowDisabledClass={styles.arrowDisabledClass}
          selected={String(selectedTabIndex)}
          scrollToSelected
          translate={0}
        />
      </section>
      {featureList.map((feature, i) => (
        <Fragment key={i}>
          {/*  <header id={convertToKebabCase(feature.title)} className={styles.featureHeader}>
            <h3 className="u-no-margin">{feature.title}</h3>
          </header> */}
          <Container>
            <Row align="center" style={{ marginTop: "0.25em", marginBottom: "0.25em" }}>
              <Col
                sm={6}
                md={6}
                push={{ sm: i % 2 !== 0 ? 6 : 0 }}
                className={`${
                  !["sm", "xs"].includes(screenClass) && i % 2 === 0 ? styles.justifyRight : ""
                } spaced-padding-vertical ${styles.featureWrapper}`}
              >
                <Slide left={i % 2 === 0} right={i % 2 !== 0}>
                  <h2>{feature.label || feature.title}</h2>
                  <p className="u-letter-spacing-std u-line-height-std">{feature.description}</p>
                </Slide>
              </Col>
              {/* <Hidden xs sm md>
                <Col md={1}></Col>
              </Hidden> */}
              <Col sm={6} pull={{ sm: i % 2 !== 0 ? 6 : 0 }} style={{ alignSelf: "stretch" }}>
                <LightboxImage>
                  {feature.srcSet ? (
                    <img
                      src={feature.srcSet[0].split(" ")[0]}
                      srcSet={feature.srcSet.join(", ")}
                      sizes="(min-width: 768px) 50vw, 100vw"
                      alt={feature.title}
                      className={styles.screenshot}
                    />
                  ) : (
                    <img src={feature.image} alt={feature.title} className={styles.screenshot} />
                  )}
                </LightboxImage>
              </Col>
            </Row>
          </Container>
        </Fragment>
      ))}
    </>
  );
};

export default Features;
