import { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
// @ts-ignore
import Shake from "react-reveal/Shake";
import Modal from "../Modal";
import Input from "../Input";
import Button from "../Button";
import styles from "./styles.module.scss";
import { downloadLocalFile } from "../../utils";
import firebase from "../../utils/firebase";
import constant from "../../utils/constants";
import { fetchData } from "../../utils/api";
import { getSendEmailConfig } from "../../utils/apiConfig";

type Props = {
  open: boolean;
  onClose: (undefined: undefined) => any;
  title?: string;
  type?: "e-book" | "whitepaper" | "checklist";
};

const { emailRegex, blacklistedEmailRegex, nameRegex } = constant.regex;

const initialState = {
  name: "",
  email: "",
};

const RequirementForm = ({ open, onClose, title, type }: Props) => {
  const [contactInfo, setContactInfo] = useState(initialState);
  const [errors, setErrors] = useState(initialState);
  const [notification, setNotification] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isFormValid = () => {
    setErrors({ name: "", email: "" });
    if (!contactInfo.name && !contactInfo.email) {
      setErrors({ name: "Please enter your full name", email: "Please enter your company email" });
      return false;
    }
    if (!(contactInfo.name && nameRegex.test(contactInfo.name))) {
      setErrors({ name: "Please enter your full name", email: "" });
      return false;
    }
    if (!contactInfo.email) {
      setErrors({ name: "", email: "Please enter your company email" });
      return false;
    }
    if (!(emailRegex.test(contactInfo.email) && !blacklistedEmailRegex.test(contactInfo.email))) {
      setErrors({ name: "", email: "Please enter your company email" });
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    if (isFormValid()) {
      setIsLoading(true);
      firebase
        .database()
        .ref(`/${type}`)
        .push(contactInfo)
        .then(() => {
          setIsLoading(false);
          if (type === "checklist") {
            downloadLocalFile("/assets/files/CCPA checklist.pdf");
            fetchData(
              getSendEmailConfig({
                email: contactInfo.email,
                contentKey: "checklist-download",
                name: contactInfo.name,
              })
            );
          } else if (type === "whitepaper") {
            downloadLocalFile("/assets/files/Penta-Privacy-Lock-Whitepaper.pdf");
            fetchData(
              getSendEmailConfig({
                email: contactInfo.email,
                contentKey: "whitepaper-download",
                name: contactInfo.name,
              })
            );
          } else {
            setNotification(`You'll be recieving our ${title} at ${contactInfo.email} shortly!`);
          }
          onClose(undefined);
          setContactInfo({ name: "", email: "" });
        });
    }
  };

  return (
    <Modal
      open={Boolean(open)}
      onClose={() => onClose(undefined)}
      title={title ? `Download ${title}` : ""}
      maxWidth="sm"
      fullWidth={true}
    >
      <Input
        label="Full Name"
        className={styles.contactInput}
        value={contactInfo.name}
        onChange={(val) => setContactInfo({ ...contactInfo, name: val })}
        autoComplete="name"
      />
      <Shake spy={errors.name}>
        <h6 className="u-no-margin input-error">{errors.name}</h6>
      </Shake>
      <Input
        label="Email"
        type="email"
        className={styles.contactInput}
        value={contactInfo.email}
        onChange={(val) => setContactInfo({ ...contactInfo, email: val })}
        autoComplete="email"
      />
      <Shake spy={errors.email}>
        <h6 className="u-no-margin input-error">{errors.email}</h6>
      </Shake>
      <Button
        className="u-full-width u-margin-bottom_1"
        variant="fancy"
        onClick={onSubmit}
        isLoading={isLoading}
      >
        {`Get ${title}`}
      </Button>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={!!notification}
        onClose={() => setNotification("")}
        message={notification}
        autoHideDuration={6000}
        classes={{ root: "toaster" }}
      />
    </Modal>
  );
};

export default RequirementForm;
