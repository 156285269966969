import React from "react";

interface Props extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  offset?: number;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => any;
  children: any;
}

const AnchorLink = (props: Props) => {
  const smoothScroll = (e: React.MouseEvent<HTMLAnchorElement>) =>  {
    e.preventDefault()
    const id = e.currentTarget.getAttribute('href')?.slice(1)
    const anchor = document.getElementById(id || '');
    if (anchor) {
      const offsetTop = anchor.getBoundingClientRect().top + window.pageYOffset;
      window.scroll({
        top: offsetTop - (props.offset || 0),
        behavior: 'smooth'
      })
    }
      if (props.onClick) {props.onClick(e)}
  }

  const { offset, ...rest } = props;
  return (
    <a {...rest} onClick={smoothScroll}>{props.children}</a>
  )
}


export default AnchorLink;
