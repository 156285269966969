import type { DataView } from "./apiConfig";

export const API_BASE = process.env.REACT_APP_API_BASE;

export const fetchData = async (view: DataView): Promise<any> =>
  new Promise(async (resolve, reject) => {
    try {
      const query = view.query ? '?' + new URLSearchParams(view.query) : '';
      const res = await fetch(`${API_BASE}${view.url}` + query , {
        headers: {
          "Content-Type": "application/json",
        },
        method: view.method || 'GET',
        body: view.data ? JSON.stringify(view.data) : undefined,
      });
      const data = await res.json();
      if (res.status === 200) {
        return resolve(data.data);
      }
      return reject(data);
    } catch (error) {
      reject(error);
    }
  });
