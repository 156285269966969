import constants from "./constants";

export const openCalendly = () => {};

export const formatPrice = (price: number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  }).format(price);

export const convertToKebabCase = (string: string) => string.replace(/\s+/g, "-").toLowerCase();

export const isValidUrl = (string?: string) => {
  try {
    const url = new URL(String(string));
    if (url) return true;
  } catch (error) {
    return false;
  }
};

export const downloadLocalFile = (filePath: string) => {
  const anchor = document.createElement("a");
  anchor.href = filePath;
  anchor.download = "";
  document.body.appendChild(anchor);
  anchor.click();
  anchor.remove();
};

export const isCalendlyEvent = (e: any) => {
  return e.data.event && e.data.event.indexOf("calendly") === 0;
};
