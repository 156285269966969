import styles from "./FullPageLoader.module.scss";

const FullPageLoader = () => (
  <div className={styles.loader}>
    <div className="u-pos-rel">
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" className="logo" data-name="Layer 1" viewBox="0 0 101.45 101.45"><defs>
      </defs><g id="Path_287-2" data-name="Path 287-2"><path className={`${styles['cls-1']} ${styles.logoBg}`} d="M71.21,88.87a21.46,21.46,0,0,0-14.38,4.77c-3.79,2.91-6.91,3.18-10.74.22A23,23,0,0,0,30.22,88.8a7.43,7.43,0,0,1-7.78-5.87A23.07,23.07,0,0,0,13,69.57C9.35,67,8.46,63.77,9.89,59.42A21.54,21.54,0,0,0,10,44.31c-1.88-4.9-.53-8.28,3.68-11.37a20.83,20.83,0,0,0,8.24-11.25c1.64-5.69,4.38-7.51,10.22-7.5A20,20,0,0,0,46.06,9.11a8,8,0,0,1,10.3,0,22.37,22.37,0,0,0,15.23,5.09c4.33-.14,7.14,1.71,8.56,6.22A24.06,24.06,0,0,0,89.79,33.7c3.61,2.46,4.07,6.13,2.8,9.95a23.07,23.07,0,0,0,0,15.76c1.6,4.39.63,7.42-3,10.15-4.71,3.51-8.22,7.61-9.62,13.57C78.92,87.57,76.14,88.88,71.21,88.87Z" style={{transform: "rotate(425deg);transform-origin: center"}}/></g><path id="Path_288" data-name="Path 288" className={styles['cls-2']} d="M70.58,65.56v3.11c0,5.81-2.81,8-8.09,5.73a28.22,28.22,0,0,0-22.26-.29c-6,2.31-8.64.44-8.22-5.88a28.21,28.21,0,0,0-7.43-21.94C21.54,43,23,39,27.4,37.75c7.88-2.29,14.65-6,18.92-13.52,3-5.36,6.54-5.33,9.84-.12,3.18,5,7,9.2,12.57,11.6a33.94,33.94,0,0,0,5,1.5c6,1.6,7.22,5.07,3.41,9.94C71.33,54.63,70.53,56.87,70.58,65.56Z"/><path className={styles["cls-1"]} d="M63.73,50a2.65,2.65,0,0,0-.94-1.54,3.56,3.56,0,0,1-1.6-3.4,10,10,0,0,0-10-9.31,10.06,10.06,0,0,0-9.9,9.45c0,1,.16,2-1,2.76-1.86,1.21-2.09,3.21-2.12,5.21,0,2.91,0,5.81,0,8.71,0,4,1.56,5.61,5.65,5.74,2.5.09,5,0,7.49,0s5,.08,7.48,0c2.25-.09,4.76-.55,5.12-3.13A40.73,40.73,0,0,0,63.73,50Zm-10,4.42c-1.5,2.22-1.11,4.52-1.22,6.84,0,1,.19,2.38-1.28,2.35S50,62.23,50,61.23c-.09-2.32.06-4.49-1.43-6.73-1.3-2,.83-2.68,2-3C53.3,51.45,54.76,52.93,53.73,54.45Zm2.32-7.23c-1.64-.16-3.31,0-5,0s-3.12-.13-4.66,0c-2.64.26-3-1.14-2.52-3.2A7.62,7.62,0,0,1,58.6,44C59.07,46.09,58.67,47.47,56.05,47.22Z"/><text className={styles["cls-3"]} transform="translate(83.46 90.55)">™</text></svg>
    </div>
  </div>
)

export default FullPageLoader;
