import { useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import styles from "./styles.module.scss";
import Button from "../../components/Button";
import RequirementForm from "../../components/RequirementForm";
import NewsCards from "../../components/NewsCards";

const Resources = () => {
  const [showWhitepaperDownloadForm, setShowWhitepaperDownloadForm] =
    useState<"e-book" | "whitepaper" | "checklist" | undefined>();
  const [modalTitle, setModalTitle] = useState('');

  const renderResourceCard = (
    image: string,
    title: string,
    type: "e-book" | "whitepaper" | "checklist"
  ) => (
    <Col sm={4} className="u-text-center">
      <div className={styles.resourceCard}>
        <img src={image} alt="" />
        <h2>{title}</h2>
        <Button
          onClick={() => {
            setShowWhitepaperDownloadForm(type);
            setModalTitle(title);
          }}
          >
            {`Download ${title}`}
          </Button>
      </div>
    </Col>
  );

  return (
    <>
      <Container>
        <Row align="center">
          <Col sm={5} md={6} lg={5}>
            <div className={styles.leftCol}>
              <h1>
                Penta Compliance <br /> News
              </h1>
              <p className="u-letter-spacing-std u-line-height-std">
                Penta Privacy Lock helps you track what information you have collected on your
                customers, so you can comply with data management requirements.
              </p>
              <Button onClick={() => {
                setModalTitle('WhitePaper');
                setShowWhitepaperDownloadForm("whitepaper");
              }}>
                Download Whitepaper
              </Button>
            </div>
          </Col>
          <Col sm={1} md={2} xl={2} className={styles.bannerHexagon} />
          <Col sm={6} md={4} xl={5} style={{ alignSelf: "stretch" }}>
            <div className={styles.whitepaperContainer} />
          </Col>
        </Row>
      </Container>
      <section className="bg-white padding-vertical_std u-margin-bottom_2">
        <Container>
          <h2>Penta Compliance News</h2>
          <p className="u-margin-bottom_2">
          Penta Privacy Lock is your source for the latest in privacy compliance news and in-sights. <br />
          Browse for the latest articles and resources related to CCPA, GDPR, and other data privacy regulations.
          </p>
          <Row>
            <NewsCards />
          </Row>
          <h2>Additional Resources</h2>
          <Row align="stretch">
            {/* {renderResourceCard(
              "../../assets/images/icon_starlock.png",
              "E-book",
              "e-book"
            )}
            {renderResourceCard("../../assets/images/technical_whitepaper.png", "Technical Whitepaper", "whitepaper")} */}
            {renderResourceCard("../../assets/images/ccpa_checklist.png", "CCPA Checklist", "checklist")}
          </Row>
        </Container>
      </section>
      <RequirementForm
        title={modalTitle}
        type={showWhitepaperDownloadForm}
        onClose={setShowWhitepaperDownloadForm}
        open={Boolean(showWhitepaperDownloadForm)}
      />
    </>
  );
};

export default Resources;
