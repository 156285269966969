import { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import "./App.scss";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import FullPageLoader from "./components/LoadingIndicators/FullPageLoader";
import Routes from "./Routes";
import firebase, { firebaseConfig } from "./utils/firebase";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

function App() {
  return (
    <Router>
      <Navbar />
      <Suspense fallback={<FullPageLoader />}>
        <Routes />
        <Footer />
      </Suspense>
    </Router>
  );
}

export default App;
