import { Col } from "react-grid-system";
import styles from "./Tiles.module.scss";

type Props = {
  iconsData: Array<{ icon: string; text: string }>;
  colProps?: { xs?: number, sm?: number, md?: number };
};

const Tiles = ({ iconsData, colProps = { xs: 6, md: 3} }: Props) => (
  <>
    {iconsData.map((tile, i) => (
      <Col {...colProps} className="u-margin-bottom_2" key={i}>
        <div className={styles.tile}>
          <img src={tile.icon} alt="" className={styles.tileIcon} />
          <h3>{tile.text}</h3>
        </div>
      </Col>
    ))}
  </>
);

export default Tiles;
