import React from "react";
import { ReactComponent as EllipsisIcon } from "../../assets/icons/icon_ellipsis.svg";
import styles from "./styles.module.scss";

type Props = {
  children: React.ReactNode;
  variant?: 'fancy';
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  type?: "button" | "submit" | "reset",
  isLoading?: boolean;
  size?: 'large' | 'small';
  title?: string;
}

const Button = ({children, variant, onClick, className = '', isLoading, size, ...props }: Props) => {
  let classNames = variant === "fancy" ? styles.fancyButton : styles.themedButton;
  if (size === 'large') {
    classNames = `${classNames} ${styles.largeButton}`;
  }
  if (size === 'small') {
    classNames = `${classNames} ${styles.smallButton}`;
  }
  return (
    <button title={props.title} onClick={onClick} disabled={isLoading} className={`${styles.btn} ${classNames} ${className}`} {...props}>
      <div className={styles.loader}>
          <span>{children}</span>
          {isLoading &&<EllipsisIcon className="u-margin-left_1" />}
      </div>
    </button>
  )
}

export default Button;
