import { ReactNode } from "react";
import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
// @ts-ignore
import Fade from "react-reveal/Fade";
import styles from "./Banner.module.scss";
import iconRight from "../../assets/icons/home_page_icon_2.svg";
import { openCalendly } from "../../utils";

type Props = {
  title: ReactNode;
  desc: ReactNode;
  imgUrl?: string;
  className?: string;
  contentCenter?: ReactNode;
};

const Banner = (props: Props) => {
  return (
    <>
      <Container className={`${styles.bannerContainer} ${props.className || ''}`}>
        <Row align="center">
          <Col xs={12} md={5} lg={4}>
            <section className={styles.leftCol}>
              <div>
                <Fade bottom>
                  <h1>{props.title}</h1>
                </Fade>
                {typeof props.desc === "string" ? <p className="u-letter-spacing-std u-line-height-std">{props.desc}</p> : props.desc}
              </div>
            </section>
          </Col>
          <Hidden sm xs>
            {/* For extra white space */}
            <Hidden md><Col lg={1}></Col></Hidden>
            <Col xs={12} md={4} style={{ alignSelf: "flex-end" }}>
              {props.imgUrl && <img src={props.imgUrl} alt="" className={styles.pcImg} />}
              {props.contentCenter}
            </Col>
          </Hidden>
          <Col md={3}>
            <Hidden md lg xl xxl>
              <div className={styles.priceColRight}>
                <Fade bottom>
                  <h3>
                    <Fade bottom>Prices start as low as</Fade>
                  </h3>
                  <span className={styles.priceTagContainer}>
                    <span className={`bg-theme color-white u-font-strong ${styles.tag}`}>
                      $50/ month
                    </span>
                    <Hidden sm xs>
                      <img className={styles.iconRight} src={iconRight} alt="" />
                    </Hidden>
                  </span>
                </Fade>
              </div>
            </Hidden>
          </Col>
        </Row>
      </Container>
      <Visible md lg xl xxl>
        <div className={styles.priceColLg}>
          <Fade bottom>
            <h3>
              <Fade bottom>Prices start as low as</Fade>
            </h3>
            <span className={styles.priceTagContainer}>
              <span role="button" onClick={openCalendly} className={`bg-theme color-white u-font-strong ${styles.tag}`}>$50/ month</span>
              <Hidden sm xs>
                <img className={styles.iconRight} src={iconRight} alt="" />
              </Hidden>
            </span>
          </Fade>
        </div>
      </Visible>
    </>
  );
};

export default Banner;
