import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Hidden, useScreenClass, Visible } from "react-grid-system";
import { Carousel } from "react-responsive-carousel";
import { DialogActions } from "@material-ui/core";
// @ts-ignore
import Fade from "react-reveal/Fade";
// @ts-ignore
import Slide from "react-reveal/Slide";

import { ReactComponent as Icon } from "../../assets/icons/icon_tick.svg";
import { ReactComponent as IconRight } from "../../assets/icons/icon_arrow_right.svg";
import styles from "./Features.module.scss";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import { getFeatureList } from "../Features/featureData";
import { convertToKebabCase } from "../../utils";
import LightboxImage from "../../components/LightboxImage";

const featureData = Array.from(getFeatureList().values());

const getFeatureContent = (
  onIconClick: () => void,
  title: string,
  desc: string | JSX.Element,
  whiteSrcSet: string[] | undefined,
  srcSet: string[] | undefined,
  imgUrl: string | undefined,
  idx: number
) => (
  <div key={idx} className="u-margin-bottom_2">
    <h2 className={styles.featureTitle}>
      {title}
      <span className="u-pointer" onClick={onIconClick}>
        <IconRight height={32} width={32} />
      </span>
    </h2>
    <p className={styles.para}>{desc}</p>
    <LightboxImage>
      {whiteSrcSet ? 
      (<img
        src={whiteSrcSet[0]}
        srcSet={whiteSrcSet.map((src) => `${src} `).join(", ")}
        alt={title}
        className={styles.screenshot}
      />) : 
      (srcSet ? (
        <img
          src={srcSet[0]}
          srcSet={srcSet.map((src) => `${src} `).join(", ")}
          alt={title}
          className={styles.screenshot}
        />
      ) : (
        <img src={imgUrl} alt={title} className={styles.screenshot} />
      ))}
    </LightboxImage>
    <Link to={`/features#${convertToKebabCase(title)}`} className={styles.anchorstyle}>
      <Button className={styles.slideButton} variant="fancy">
        See it in Action
      </Button>
    </Link>
  </div>
);

const getFeaturesAndServices = (onIconClick: () => void) =>
  featureData.map((feature, i) => ({
    title: feature.title,
    content: getFeatureContent(
      onIconClick,
      feature.title,
      feature.description,
      feature.whiteSrcSet,
      feature.srcSet,
      feature.image,
      i
    ),
  }));

const Features = () => {
  const [selectedSlideIndex, setSelectedSlideIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const screenClass = useScreenClass();

  // 7 is index of last slide. If more slides are added, change this logic.
  const onArrowClick = () =>
    setSelectedSlideIndex(selectedSlideIndex === 6 ? 0 : selectedSlideIndex + 1);

  const featuresAndServices = getFeaturesAndServices(onArrowClick);

  const featureList = featuresAndServices.map((feature, i) => (
    <Slide left key={i}>
      <div
        key={i}
        className={`${styles.featureRow} ${selectedSlideIndex === i ? styles.currentItem : ""} ${
          selectedSlideIndex >= i ? styles.shown : ""
        }`}
        onClick={() => {
          setSelectedSlideIndex(i);
          if (screenClass === "xs") {
            setIsModalOpen(true);
          }
        }}
      >
        <Icon height={20} width={20} />
        <h4>{feature.title}</h4>
      </div>
    </Slide>
  ));

  return (
    <section className={styles.toolkitContainer}>
      <header className={styles.toolkitHeader}>
        <Container>
          <div className={styles.toolkitContent}>
            <Row>
              <Col xs={12} md={9} lg={8}>
                <Fade bottom>
                  <h1 className={styles.heading}>Let us do the work for you!</h1>
                </Fade>
                <p className={`${styles.para} u-text-left`}>
                  Penta Privacy Lock is #1 in CCPA compliance automation.
                  <br />
                  Find out how our automation toolkit can help your business <br />
                  implement data privacy compliance today.
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </header>
      <div className={styles.grid}>
        <div className={styles.featureListContainer}>
          <h2 className={styles.paddingLeft}>
            Features
            <Visible xs>
              <h6 className="u-no-margin">
                <i>Click to see more information</i>
              </h6>
            </Visible>
          </h2>
          {featureList}
        </div>
        <Hidden xs sm>
          <div className={styles.gridRight}>
            <Carousel
              showArrows={false}
              // autoPlay={screenClass !== "xs"}
              selectedItem={selectedSlideIndex}
              showIndicators={false}
              showStatus={false}
              onChange={(index) => setSelectedSlideIndex(index)}
              showThumbs={false}
            >
              {featuresAndServices.map((feature, i) => (
                <React.Fragment key={i}>{feature.content}</React.Fragment>
              ))}
            </Carousel>
          </div>
        </Hidden>
      </div>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby={featuresAndServices[selectedSlideIndex].title}
      >
        {featuresAndServices[selectedSlideIndex].content}
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)}>Close</Button>
        </DialogActions>
      </Modal>
    </section>
  );
};

export default Features;
